import React, { useMemo } from "react";

const CustomButton = React.memo(
  ({ name, bgColor = "", className = "", style = {}, icon: Icon, ...rest }) => {
    const buttonStyle = useMemo(
      () => ({
        backgroundColor: bgColor,
        border: "1px solid #EEF2F8",
        padding: "5px 20px",
        color: "white",
        cursor: "pointer",
        outline: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: Icon ? "center" : "",
        ...style,
      }),
      [bgColor, style, Icon]
    );

    return (
      <button {...rest} style={buttonStyle} className={` ${className}`}>
        {Icon && (
          <span className="mr-2">
            <Icon />
          </span>
        )}
        {name}
      </button>
    );
  }
);

CustomButton.displayName = "CustomButton";

export default CustomButton;
