import React from "react";
import Skeleton from "@mui/material/Skeleton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomDropdownCheckboxSkeleton = ({
  width = 150,
  dropdownHeight = 35,
  menuWidth = 150,
  menuHeight = 200,
}) => {
  return (
    <div style={{ position: "relative", width, borderRadius: "20px" }}>
      <div
        style={{
          height: dropdownHeight,
          border: "1px solid #D7DEE8",
          display: "flex",
          alignItems: "center",
          padding: "0 8px",
          justifyContent: "space-between",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <Skeleton variant="text" width={width - 40} height={20} />
        <KeyboardArrowDownIcon />
      </div>
      {/* <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          width: menuWidth,
          maxHeight: menuHeight,
          backgroundColor: "white",
          border: "1px solid #D1D5DB",
          borderRadius: "8px",
          zIndex: 1000,
          marginTop: 8,
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      > */}
      {/* {[...Array(5)].map((_, index) => (
          <div
            key={index}
            style={{
              padding: "4px 8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton
              variant="text"
              width={menuWidth - 60}
              height={20}
              style={{ marginLeft: "8px" }}
            />
          </div>
        ))} */}
      {/* </div> */}
    </div>
  );
};

export default CustomDropdownCheckboxSkeleton;
