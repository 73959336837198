import React, { useState } from "react";
import { Coins } from "../../../../components/icons";
import CircularGraph from "./CircularGraph";
import GradientBarChart from "./GradientBarChart";
import MarketingRevenuePerformance from "./Marketing-Revenue-Performance";
import ActivityHeatMap from "./ActivityHeatMap";
import HeatmapChart from "./HeatmapChart";

const Overview = () => {
  const [activeTab, setActiveTab] = useState("adSpend");

  return (
    <div className="w-full h-full">
      <div className="grid grid-cols-12 gap-4 relative">
        <div className="col-span-3 bg-white rounded-lg shadow-md p-4 flex flex-col justify-between ">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[#515F6F]">Total marketing spend</p>
              <p className="text-primary-7 font-bold">£40,260.54</p>
            </div>
            <div className="bg-[#E9F9F2] p-2 rounded-lg flex items-center justify-center -mt-4">
              <Coins />
            </div>
          </div>
          <div className="">
            <div className="w-full flex justify-center">
              <CircularGraph />
            </div>
          </div>
          <div className=" w-full p-2">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                <p className="text-base font-400 text-[#515F6F]">Adverts</p>
              </div>
              <p className="text-primary-7 font-bold">£12,352.10</p>
            </div>
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-[#A500FF] rounded-full mr-2"></div>
                <p className="text-[#515F6F]">Offers</p>
              </div>
              <p className="text-primary-7 font-bold">£24,562.50</p>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-primary-4 rounded-full mr-2"></div>
                <p className="text-[#515F6F]">Left to spend</p>
              </div>
              <p className="text-primary-7 font-bold">£3,345.94</p>
            </div>
          </div>
        </div>
        <div className="col-span-9 bg-white shadow-md p-4 rounded-md">
          <div className="flex justify-start items-center gap-x-3">
            <button
              className={`py-2 px-4 text-center ${
                activeTab === "adSpend"
                  ? "text-primary-7  font-semibold active-tb-1012"
                  : "text-primary-6"
              }`}
              onClick={() => setActiveTab("adSpend")}
            >
              Ad spend
            </button>
            <button
              className={`py-2 px-4 text-center ${
                activeTab === "promotion"
                  ? "text-primary-7  font-semibold active-tb-1012 "
                  : "text-primary-6"
              }`}
              onClick={() => setActiveTab("promotion")}
            >
              Promotion spend
            </button>
          </div>

          <div className="mt-4 ">
            {activeTab === "adSpend" && (
              <div className="">
                <GradientBarChart />
              </div>
            )}
            {activeTab === "promotion" && (
              <div>
                <h2 className="text-lg font-bold">Promotion Content</h2>
                <p>Details about promotions go here.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="shadow-md mt-10 p-2 rounded-md">
        <MarketingRevenuePerformance />
      </div>

      <div className="shadow-md mt-10 p-4 rounded-md">
        <ActivityHeatMap />
      </div>
    </div>
  );
};

export default Overview;
