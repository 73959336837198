import React from "react";
import ParantDatePicker from "../../../../components/global-components/CustomDatePicker/ParantDatePicker";
const OverviewDatePicker = () => {
  return (
    <div>
      <ParantDatePicker />
    </div>
  );
};

export default OverviewDatePicker;
