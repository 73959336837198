import React, { useState } from "react";
import Searchbar from "../../../../components/shared/Searchbar";
import Pagination from "../../../../components/global-components/Pagination";
import SiteReviewAddFilter from "./SiteReviewAddFilter";

const SiteReview = () => {
  // State to manage pagination details
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const totalItems = 100; // For example, assume there are 100 items
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div>
          <p className="text-primary-7 text-lg font-semibold">Site review</p>
        </div>
        <div className="flex items-center justify-center gap-x-4">
          <Searchbar />
          <SiteReviewAddFilter />
          <Pagination
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SiteReview;
