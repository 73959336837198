import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DatePickerSkeleton = () => {
  return (
    <div className="relative flex items-center gap-2">
      <div className="flex items-center gap-4">
        <Skeleton circle width={20} height={20} />
        <Skeleton width={150} height={20} />
        <Skeleton circle width={20} height={20} />
      </div>
      <Skeleton width={40} height={40} className="mr-4" />
    </div>
  );
};

export default DatePickerSkeleton;
