import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CalendarGreydownArrow, CalendarGreyUpArrow } from "../../icons";

const SingleDaySelectCalendar = ({
  onDateSelect,
  toggleDatePicker,
  selectedDates,
  onReset,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date(selectedDates[0]));
  const [internalSelectedDates, setInternalSelectedDates] =
    useState(selectedDates);

  useEffect(() => {
    setCurrentDate(new Date(selectedDates[0]));
    setInternalSelectedDates(selectedDates);
  }, [selectedDates]);

  const header = () => (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center space-x-4">
        <button className="text-gray-600 text-xl" onClick={prevMonth}>
          <FaChevronLeft size={15} color="#333B48" />
        </button>
        <span className="text-lg font-bold">
          {currentDate.toLocaleString("default", { month: "long" })}
        </span>
        <button
          className="text-gray-600 text-xl"
          onClick={nextMonth}
          disabled={isCurrentMonthOrFuture()}
        >
          <FaChevronRight
            color={isCurrentMonthOrFuture() ? "#A1AEBF" : "#333B48"}
            size={15}
          />
        </button>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-base font-medium">
          {currentDate.getFullYear()}
        </span>
        <div className="flex flex-col gap-3">
          <button
            className="text-gray-600 text-xs"
            onClick={nextYear}
            disabled={isCurrentYearOrFuture()}
          >
            <CalendarGreyUpArrow
              color={isCurrentYearOrFuture() ? "#A1AEBF" : "#333B48"}
            />
          </button>
          <button className="text-gray-600 text-xs" onClick={prevYear}>
            <CalendarGreydownArrow />
          </button>
        </div>
      </div>
    </div>
  );

  const daysOfWeek = () => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return (
      <div className="grid grid-cols-7 gap-1 mb-2 px-2">
        {days.map((day) => (
          <div
            key={day}
            className="text-center font-semibold text-sm text-gray-600"
          >
            {day}
          </div>
        ))}
      </div>
    );
  };

  const cells = () => {
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const startingDayOfWeek = firstDayOfMonth.getDay() || 7; // 1-7 (Monday-Sunday)
    const daysInMonth = lastDayOfMonth.getDate();

    const rows = [];
    let days = [];

    // Add empty cells for days before the first of the month
    for (let i = 1; i < startingDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="p-0 text-center" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const isToday = isSameDay(date, new Date());
      const isSelected = internalSelectedDates.some((d) => isSameDay(d, date));
      const isFuture = date > new Date();

      const cellClassName = `formated-date2 flex justify-center items-center ${
        isSelected ? "text-white" : "text-[#515f6f]"
      } ${isFuture ? "cursor-not-allowed text-gray-300" : "cursor-pointer"} ${
        isSelected ? "bg-orange-500 rounded-full text-center" : ""
      } ${
        isToday && !internalSelectedDates.length
          ? "bg-orange-500 rounded-full text-white"
          : ""
      }`;

      const cell = (
        <div
          key={day}
          className={cellClassName}
          onClick={() => !isFuture && handleDateClick(date)}
        >
          {day}
        </div>
      );
      days.push(cell);

      if (days.length === 7 || day === daysInMonth) {
        rows.push(
          <div className="grid grid-cols-7 my-selected-range" key={day}>
            {days}
          </div>
        );
        days = [];
      }
    }

    return <div className="bg-white p-2 parent-cls">{rows}</div>;
  };

  const handleDateClick = (date) => {
    const isSelected = internalSelectedDates.some((d) => isSameDay(d, date));
    if (isSelected) {
      setInternalSelectedDates(
        internalSelectedDates.filter((d) => !isSameDay(d, date))
      );
    } else {
      setInternalSelectedDates([...internalSelectedDates, date]);
    }
  };

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    if (!isCurrentMonthOrFuture()) {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
    }
  };

  const prevYear = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1)
    );
  };

  const nextYear = () => {
    if (!isCurrentYearOrFuture()) {
      setCurrentDate(
        new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), 1)
      );
    }
  };

  const isCurrentMonthOrFuture = () => {
    const today = new Date();
    return (
      currentDate.getFullYear() >= today.getFullYear() &&
      currentDate.getMonth() >= today.getMonth()
    );
  };

  const isCurrentYearOrFuture = () => {
    return currentDate.getFullYear() >= new Date().getFullYear();
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const resetSelection = () => {
    setInternalSelectedDates([]);
    setCurrentDate(new Date());
    onReset();
  };

  const applySelection = () => {
    if (internalSelectedDates.length > 0) {
      const selectedDatesISO = internalSelectedDates.map((date) =>
        date.toISOString()
      );
      onDateSelect(selectedDatesISO);
      toggleDatePicker();
    }
  };

  return (
    <div className="w-full max-w-[320px] mx-auto">
      <div className="border rounded-lg shadow-md overflow-hidden bg-white">
        {header()}
        {daysOfWeek()}
        {cells()}
        <div className="flex justify-end p-4 border-t gap-4">
          <button
            className="text-black font-bold flex items-center"
            onClick={resetSelection}
          >
            <span className="mr-2">🗑</span> Reset
          </button>
          <button
            className="text-orange-500 font-semibold"
            onClick={applySelection}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleDaySelectCalendar;
