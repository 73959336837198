// src/components/Heatmap.js
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const HeatmapChart = () => {
  const hours = [
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // Example data, use appropriate values for your heatmap
  const generateData = () => {
    return Array.from({ length: days.length }, () => {
      return Array.from({ length: hours.length }, () =>
        Math.floor(Math.random() * 100)
      );
    });
  };

  const heatmapData = generateData();

  const data = {
    labels: hours,
    datasets: days.map((day, index) => ({
      label: day,
      data: heatmapData[index],
      backgroundColor: heatmapData[index].map((value) => {
        const alpha = value / 100;
        return `rgba(54, 162, 235, ${alpha})`;
      }),
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
    })),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Activity Heatmap",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time of Day",
        },
      },
      y: {
        title: {
          display: true,
          text: "Day of Week",
        },
        reverse: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HeatmapChart;
