import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomCheckbox from "./CustomCheckbox";

const CustomDropdownCheckbox = React.memo(
  ({
    options = [],
    selectAllText = "Select All",
    selectAllValue = "All",
    defaultSelectAll = false,
    activeValues = [],
    width = 150,
    menuHeight = 200,
    menuWidth = 150,
    dropdownHeight = 35,
    onSelectionChange = () => {},
    dropdownType = "",
    checkboxColor = "#fb923c",
    activeBorderColor = "rgb(251, 146, 60)",
    placeholder = "Select options",
    customBorderColor = "#D7DEE8",
    customBorderRadius = 2,
    customBorderWidth = 1,
    left = 0,
    showMenu = true,
  }) => {
    const [selectedValues, setSelectedValues] = React.useState(() => {
      if (defaultSelectAll) {
        return options.map((option) => option.value);
      }
      return activeValues.length > 0 ? activeValues : [];
    });

    React.useEffect(() => {
      if (defaultSelectAll) {
        const arr = options.map((option) => option.value);
        setSelectedValues(arr);
        // return "";
      } else {
        if (activeValues.length > 0) {
          setSelectedValues(activeValues);
        }
      }
    }, [defaultSelectAll]);

    const [open, setOpen] = React.useState(false);
    const menuRef = React.useRef(null);

    React.useEffect(() => {
      if (open && menuRef.current) {
        menuRef.current.scrollTop = 0;
      }
    }, [open]);

    const handleToggle = () => setOpen(!open);

    const handleClickAway = () => setOpen(false);

    const handleChange = (value) => {
      let newSelectedValues;
      if (value === "select-all") {
        newSelectedValues =
          selectedValues.length === options.length
            ? []
            : options.map((option) => option.value);
      } else {
        newSelectedValues = selectedValues.includes(value)
          ? selectedValues.filter((v) => v !== value)
          : [...selectedValues, value];
      }
      setSelectedValues(newSelectedValues);
      onSelectionChange(newSelectedValues, dropdownType);
    };

    const renderValue = () => {
      if (selectedValues.length === 0) {
        return placeholder;
      }
      if (selectedValues.length === options.length) {
        return selectAllValue || "All";
      }
      if (selectedValues.length === 1) {
        const selectedOption = options.find(
          (option) => option.value === selectedValues[0]
        );
        return selectedOption ? selectedOption.label : selectedValues[0];
      }
      return `${selectedValues.length} selected`;
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ position: "relative", width, borderRadius: "20px" }}>
          <div
            className="hover:border-orange-5 border-solid my-dropdownd"
            onClick={handleToggle}
            style={{
              height: dropdownHeight,
              border: `${customBorderWidth}px solid ${customBorderColor}`,

              display: "flex",
              alignItems: "center",
              padding: "0 8px",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "white",
              color: selectedValues.length === 0 ? "gray" : "inherit",
              borderRadius: "8px",
            }}
          >
            <span className="truncate">{renderValue()}</span>
            <KeyboardArrowDownIcon />
          </div>
          {open && (
            <div
              className="custom-scrollbar"
              ref={menuRef}
              style={{
                position: "absolute",
                top: "100%",
                left: left,
                width: menuWidth,
                maxHeight: menuHeight,
                overflowY: "auto",
                backgroundColor: "white",
                border: "1px solid #D1D5DB",
                borderRadius: "8px",
                zIndex: 1000,
                marginTop: 8,

                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#515F6F",
                  borderRadius: "4px",
                },
              }}
            >
              {showMenu && (
                <div
                  onClick={() => handleChange("select-all")}
                  style={{
                    padding: "4px 8px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <Checkbox
                    checked={selectedValues.length === options.length}
                    sx={{
                      color: "#A1AEBF",
                      padding: "0px 5px",
                      "& .MuiSvgIcon-root": {
                        // fontSize: 70,
                        borderRadius: 40,
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        borderRadius: "40px",
                        color: checkboxColor,
                      },
                    }}
                  /> */}
                  <CustomCheckbox
                    checked={selectedValues.length === options.length}
                  />
                  <span style={{ fontWeight: "600", marginLeft: "8px" }}>
                    {selectAllText}
                  </span>
                </div>
              )}
              {showMenu &&
                options.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => handleChange(option.value)}
                    style={{
                      padding: "4px 8px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomCheckbox
                      checked={selectedValues.includes(option.value)}
                    />
                    {/* <Checkbox
                      checked={selectedValues.includes(option.value)}
                      sx={{
                        borderRadius: "20px",
                        padding: "0px 5px",
                        color: "#A1AEBF",
                        "&.Mui-checked": {
                          color: checkboxColor,
                          "& .MuiSvgIcon-root": {
                            borderRadius: "40px",
                          },
                        },
                      }}
                    /> */}
                    <span style={{ marginLeft: "8px" }}>{option.label}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
);

export default CustomDropdownCheckbox;
