import AdvertsAllocationBudget from "./AdvertsAllocationBudget";
import SiteReview from "./SiteReview";
import Table from "./Table";

const Adverts = () => {
  return (
    <>
      <AdvertsAllocationBudget />
      <SiteReview />
      <Table />
    </>
  );
};

export default Adverts;
