import React from 'react';

const CustomArrow = ({ className, style, onClick, direction }) => {
  const baseStyles = `absolute top-[34%] transform -translate-y-1/2 w-[2rem] h-[2rem] rounded-full flex items-center justify-center bg-[#071B32] text-white cursor-pointer flex`;

  const directionStyles = direction === 'left' ? 'left-[-16px]' : 'right-[-12px]';

  return (
    <div
      className={`${baseStyles} ${directionStyles}`}
      style={{ ...style, zIndex: 1, display:'flex', justifyContent:'center', alignItems:'center' }}
      onClick={onClick}
    >
      {direction === 'left' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      )}
    </div>
  );
};

export const NextArrow = (props) => <CustomArrow {...props} direction="right" />;
export const PrevArrow = (props) => <CustomArrow {...props} direction="left" />;
