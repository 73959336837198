import { createSlice } from "@reduxjs/toolkit";
import { format, startOfWeek, endOfWeek } from "date-fns";

const today = new Date();
const lastMonday = startOfWeek(today, { weekStartsOn: 1 });
const lastSaturday = endOfWeek(today, { weekStartsOn: 1 });

const initialState = {
  startDate: format(lastMonday, "yyyy-MM-dd"),
  endDate: format(lastSaturday, "yyyy-MM-dd"),
  location: [],

  addFilter: {
    opscore: [],
    ratings: [],
    sales: [],
    status: [],
    itemperpage: null,
    pageSize: null,
    totalpages: null,
    weeklyOperationsData: [],
  },

  markerPositions: [],

  search: "",
  appliedFilterCount: 0,
  locationDates: {
    start: format(lastMonday, "yyyy-MM-dd") || "",
    end: format(lastSaturday, "yyyy-MM-dd") || "",
  },
  weeklyDates: {
    start: format(lastMonday, "yyyy-MM-dd") || "",
    end: format(lastSaturday, "yyyy-MM-dd") || "",
  },

  locationData: {
    locationName: null,
    date: null,
  },
  selectedLocations: ["all"],
};

// Create a slice of the Redux store
const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilterStartDate(state, action) {
      const { id, startDate } = action.payload;
      if (!state.dateRanges[id]) {
        state.dateRanges[id] = {};
      }
      state.dateRanges[id].startDate = startDate;
    },
    setFilterEndDate(state, action) {
      const { id, endDate } = action.payload;
      if (!state.dateRanges[id]) {
        state.dateRanges[id] = {};
      }
      state.dateRanges[id].endDate = endDate;
    },
    setFilterSearch(state, action) {
      state.search = action.payload;
    },
    setFilterItemPerPage(state, action) {
      state.itemperpage = action.payload;
    },
    setFilterTotalPage(state, action) {
      state.totalpages = action.payload;
    },

    setFilterValue(state, action) {
      const { dropdownType, selectedValues } = action.payload;
      state.addFilter[dropdownType] = selectedValues;
    },

    setweeklyOperationsData(state, action) {
      state.addFilter.weeklyOperationsData = action.payload;
    },

    checkAppliedFilters(state) {
      let count = 0;
      Object.values(state.addFilter).forEach((array) => {
        if (array?.length > 0) {
          count++;
        }
      });
      state.appliedFilterCount = count;
    },

    clearAllFilters(state, action) {
      const id = action?.payload?.id;
      const startDate = action?.payload?.startDate;
      const endDate = action?.payload?.endDate;

      if (id && startDate && endDate) {
        state.addFilter = {
          opscore: [],
          ratings: [],
          sales: [],
          status: [],
        };
        // if (state?.[id]) {
        //   state[id] = {
        //     start: format(startDate, "yyyy-MM-dd") || "",
        //     end: format(endDate, "yyyy-MM-dd") || "",
        //   };
        // }
        // state.weeklyDates = {
        //   start: format(lastMonday, "yyyy-MM-dd") || "",
        //   end: format(lastSaturday, "yyyy-MM-dd") || "",
        // };
        state.selectedLocations = ["all"];
        state.appliedFilterCount = 0;
      } else {
        state.addFilter = {
          opscore: [],
          ratings: [],
          sales: [],
          status: [],
        };
        // state.locationDates = {
        //   start: format(lastMonday, "yyyy-MM-dd") || "",
        //   end: format(lastSaturday, "yyyy-MM-dd") || "",
        // };
        // state.weeklyDates = {
        //   start: format(lastMonday, "yyyy-MM-dd") || "",
        //   end: format(lastSaturday, "yyyy-MM-dd") || "",
        // };
        state.selectedLocations = ["all"];
        state.appliedFilterCount = 0;
      }
    },
    setDateRange() {},

    clearAddFilters(state, action) {
      state.addFilter = {
        opscore: [],
        ratings: [],
        sales: [],
        status: [],
      };

      // state.selectedLocations = ["all"];
      state.appliedFilterCount = 0;
    },

    handleDateChange(state, action) {
      const { id, startDate, endDate } = action.payload;

      state[id].start = startDate;
      state[id].end = endDate;
    },

    addMarkerPosition(state, action) {
      state.markerPositions = action.payload;
    },

    setLocationData(state, action) {
      state.locationData = action.payload;
    },
    setSelectedLocation(state, action) {
      state.selectedLocations = action.payload;
    },
  },
});

export const {
  setFilterStartDate,
  setFilterEndDate,
  setFilterSearch,
  setFilterValue,
  checkAppliedFilters,
  clearAllFilters,
  handleDateChange,
  setFilterItemPerPage,
  setFilterTotalPage,
  addMarkerPosition,
  setweeklyOperationsData,
  setLocationData,
  setSelectedLocation,
  clearAddFilters,
  setDateRange,
} = filterSlice.actions;

export default filterSlice.reducer;
