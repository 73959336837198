import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const data = [
  { month: "Jul", revenue: 17000 },
  { month: "Aug", revenue: 15500 },
  { month: "Sep", revenue: 18000 },
  { month: "Oct", revenue: 13500 },
  { month: "Nov", revenue: 11500 },
  { month: "Dec", revenue: 11678.9 },
  { month: "Jan", revenue: 11000 },
  { month: "Feb", revenue: 14000 },
  { month: "Mar", revenue: 15000 },
  { month: "Apr", revenue: 12000 },
  { month: "May", revenue: 15000 },
  { month: "Jun", revenue: 12000 },
];

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <rect x={x} y={y} width={width} height={height} fill={fill} />;
};

const GradientBarChart = () => {
  return (
    <div className="w-full h-[350px]  p-2 rounded-lg ">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 5, bottom: 5 }}
        >
          <defs>
            <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="#17E080
"
              />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} horizontal={true} stroke="#e0e0e0" />
          <XAxis dataKey="month" axisLine={false} tickLine={false} />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => `£${value / 1000}k`}
          />
          <Tooltip
            formatter={(value) => [`£${value.toFixed(2)}`, "Revenue"]}
            labelFormatter={(label) => `${label}, 2024`}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey="revenue"
            fill="url(#colorRevenue)"
            shape={<CustomBar width={55} />}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GradientBarChart;
