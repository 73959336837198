import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
export const BASE_API_URL = "https://api.groki.ai";
// export const BASE_API_URL = "http://192.168.31.112:4006";
const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:4006" + "/api/",
  baseUrl: BASE_API_URL + "/api/",
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("token"); //getState().auth.token || ""

    if (token) {
      headers.set("Authorization", token);
    }
    return headers;
  },
});

export const api = createApi({
  baseQuery,
  endpoints: (builder) => ({
    callGet: builder.query({
      query: (url) => ({ url, method: "GET" }),
    }),
    callPost: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: "POST",
        body,
      }),
    }),

    callFileUpload: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { url, file, onUploadProgress } = arg;
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await axios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress,
          });
          return { data: response.data };
        } catch (error) {
          return {
            error: {
              status: error.response?.status,
              data: error.response?.data,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useCallGetQuery,
  useCallPostMutation,
  useCallFileUploadMutation,
} = api;
