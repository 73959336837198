// CustomButton.js
import React from "react";

const CustomButton = ({
  name = "Button",
  onClick,
  style = {},
  className = "",
  icon: Icon = null,
  disabled = false,
  type = "button",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={style}
      className={`custom-button ${className} ${disabled ? "disabled" : ""}`}
      disabled={disabled}
    >
      {Icon && <Icon className="icon" />}
      <span>{name}</span>
    </button>
  );
};

export default CustomButton;
