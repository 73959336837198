import React, { useEffect, useState } from "react";
import CustomDropdownCheckbox from "../../../components/global-components/CustomDropdownChekbox";
import { Motorcycle } from "../../../components/icons";
import CustomTable from "../../../components/global-components/CustomTable";
import Pagination from "../../../components/global-components/Pagination";
import CustomDropdownCheckboxSkeleton from "../../../components/skelaton/DrodownSkelton";

const DailyTable = ({
  onSortChange,
  loading,
  options,
  dailyOperationRefundsData,
  totalPages,
  currentPage,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const [dropdownSortValue, setDropdownSortValue] = useState(["all"]);
  // Handle page change
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (items) => {
    onItemsPerPageChange(items);
  };

  const filteredOptions = options?.data
    ? Object.keys(options.data)
        .filter((key) => options.data[key])
        .map((key) => ({
          value: key.toLowerCase(),
          label: key.charAt(0).toUpperCase() + key.slice(1),
        }))
    : [];

  const handleSelectionChange = (selectedValues) => {
    const sortValues = selectedValues.length > 0 ? selectedValues : [];
    setDropdownSortValue(sortValues);
    console.log("xxxxxxxxxxxxxx", sortValues);
    onSortChange(sortValues);
  };

  console.log("filteredOptions", { filteredOptions });

  useEffect(() => {
    handleSelectionChange(["all"]);
  }, []);

  return (
    <div className="bg-white w-full rounded-lg shadow my-5 px-6 py-4">
      <div className="w-full pl-2 flex justify-between items-center flex-wrap">
        <h2 className="text-xl font-semibold">Refunds</h2>
        <div className="flex gap-5 items-center justify-center pb-4 flex-wrap">
          <div className="flex justify-center items-center gap-2">
            <p className="text-center text-nowrap ml-1">{<Motorcycle />}</p>
            <p className="text-center text-nowrap text-primary-6">
              Delivery App
            </p>
          </div>
          <div className="flex justify-end items-center gap-4">
            <CustomDropdownCheckbox
              options={filteredOptions}
              dropdownType={"DailyOperationsLocation"}
              defaultSelectAll={filteredOptions.length ? true : false}
              dropdownHeight={39}
              customBorderColor={"#A1AEBF"}
              customBorderRadius={2}
              customBorderWidth={2}
              menuHeight={200}
              menuWidth={160}
              onSelectionChange={handleSelectionChange}
            />

            <Pagination
              totalPages={totalPages} // This can be calculated based on data
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </div>
        </div>
      </div>
      <CustomTable
        tabledata={dailyOperationRefundsData}
        refundLoading={loading}
      />
    </div>
  );
};

export default DailyTable;
