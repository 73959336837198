import React from "react";
import Skeleton from "react-loading-skeleton";

const DailySales = ({ weeklyLoading, getDailySalesData }) => {
  // Calculate total sales
  const deliverooSales =
    parseFloat(getDailySalesData?.data?.sales?.deliveroo?.totalSales) || 0;
  const justeatSales =
    parseFloat(getDailySalesData?.data?.sales?.justeat?.totalSales) || 0;
  const uberSales =
    parseFloat(getDailySalesData?.data?.sales?.uber?.totalSales) || 0;
  const totalSales = deliverooSales + justeatSales + uberSales;

  // Calculate percentage for each platform
  const getPercentage = (sales) =>
    totalSales ? (sales / totalSales) * 100 : 0;

  return (
    <div className="bg-white px-4 py-2 rounded-lg shadow-md flex flex-col h-full">
      {weeklyLoading ? (
        <>
          <div className="flex gap-1 items-center mb-4">
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={10} />
          </div>
          <div className="mt-4">
            <div className="grid grid-cols-3 items-center gap-14 mb-4">
              <div className="flex items-center col-span-1">
                <Skeleton circle={true} width={24} height={24} />
                <Skeleton width={80} className="ml-2" />
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1"></div>
              <Skeleton width={80} height={16} className="col-span-1" />
            </div>
            <div className="grid grid-cols-3 items-center gap-14 mb-4">
              <div className="flex items-center col-span-1">
                <Skeleton circle={true} width={24} height={24} />
                <Skeleton width={80} className="ml-2" />
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1"></div>
              <Skeleton width={80} height={16} className="col-span-1" />
            </div>
            <div className="grid grid-cols-3 items-center gap-14 mb-4">
              <div className="flex items-center col-span-1">
                <Skeleton circle={true} width={24} height={24} />
                <Skeleton width={80} className="ml-2" />
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1"></div>
              <Skeleton width={80} height={16} className="col-span-1" />
            </div>
          </div>
        </>
      ) : (
        <>
          <h3 className="text-md font-medium text-primary-5">Daily Sales</h3>
          <div className="flex gap-1 items-center mt-[6px]">
            <p className="text-xl font-bold">£{parseInt(totalSales)}</p>
            {/* <Growthicon /> Replace with Declineicon if needed */}
          </div>
          <div className="mt-4">
            <div className="grid grid-cols-3 items-center gap-4 mb-4">
              <div className="flex items-center col-span-1">
                <img
                  src="/deliveroo.png"
                  alt="Deliveroo"
                  className="w-8 h-8 mr-2"
                />
                <span className="text-base font-semibold">Deliveroo</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1 relative">
                <div
                  className="bg-green-500 h-1.5 rounded-full absolute"
                  style={{ width: `${getPercentage(deliverooSales)}%` }}
                ></div>
              </div>
              <span className="font-semibold col-span-1">
                £{parseInt(deliverooSales)}
              </span>
            </div>
            <div className="grid grid-cols-3 items-center gap-4 mb-4">
              <div className="flex items-center col-span-1">
                <img
                  src="/justeat.png"
                  alt="JustEat"
                  className="w-8 h-8 mr-2"
                />
                <span className="text-base font-semibold">JustEat</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1 relative">
                <div
                  className="bg-green-500 h-1.5 rounded-full absolute"
                  style={{ width: `${getPercentage(justeatSales)}%` }}
                ></div>
              </div>
              <span className="font-semibold col-span-1">£{justeatSales}</span>
            </div>
            <div className="grid grid-cols-3 items-center gap-4 mb-4">
              <div className="flex items-center col-span-1">
                <img
                  src="/ubereats.png"
                  alt="UberEats"
                  className="w-8 h-8 mr-2"
                />
                <span className="text-base font-semibold">Uber Eats</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1 relative">
                <div
                  className="bg-green-500 h-1.5 rounded-full absolute"
                  style={{ width: `${getPercentage(uberSales)}%` }}
                ></div>
              </div>
              <span className="font-semibold col-span-1">£{uberSales}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DailySales;
