import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";

// Register the required components in Chart.js
ChartJS.register(Tooltip, Legend, ArcElement);

const DoughnutChart = () => {
  // Define the data for the chart
  const data = {
    labels: ["Purple", "Green", "Light Blue"],
    datasets: [
      {
        data: [30, 40, 30], // Data values (adjust as needed)
        backgroundColor: ["#A500FF", "#17E080", "#EEF2F8"], // Segment colors
        borderColor: "#ffffff", // Border color for each segment
        borderWidth: 2, // Border width for each segment
      },
    ],
  };

  // Define options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
    cutout: "60%",
    elements: {
      arc: {
        borderRadius: 20, // Apply a consistent border radius to all segments
      },
    },
  };

  return (
    <div className="w-40 h-40">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
