import React, { useState, useEffect } from "react";
import {
  format,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  addYears,
  subYears,
  isSameMonth,
  isSameDay,
  addDays,
  isWithinInterval,
  startOfMonth,
  endOfMonth,
  isAfter,
  subWeeks,
  subDays,
} from "date-fns";
import { CalendarGreydownArrow, CalendarGreyUpArrow } from "../../icons";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const FinalDatePicker = ({
  onDateRangeSelect,
  defaultDateRange,
  onReset,
  defaultStartDate,
  defaultEndDate,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const ActualCurrentDate = new Date();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(true);
  const [displayedDateRange, setDisplayedDateRange] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [maxSelectableDate, setMaxSelectableDate] = useState(null);

  useEffect(() => {
    if (defaultStartDate && defaultEndDate) {
      const start = defaultStartDate;
      const end = defaultEndDate;

      const weekStart = startOfWeek(start, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(end, { weekStartsOn: 1 });

      setSelectedStartDate(weekStart);
      setSelectedEndDate(weekEnd);
      setCurrentDate(start);
      setSelectedMonth(start);
      setMaxSelectableDate(end); // Set the maxSelectableDate to the end date
      updateDisplayedDateRange(weekStart, weekEnd);
    }
  }, [defaultDateRange]);

  const updateDisplayedDateRange = (start, end) => {
    if (start && end) {
      const formattedStart = format(start, "d MMM, yyyy");
      const formattedEnd = format(end, "d MMM, yyyy");
      setDisplayedDateRange(`${formattedStart} - ${formattedEnd}`);
    } else {
      setDisplayedDateRange("");
    }
  };

  const onDateClick = (day, isDisabled) => {
    // if (isAfter(day, maxSelectableDate)) return;
    if (isDisabled) {
      return "";
    }

    const weekStart = startOfWeek(day, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(day, { weekStartsOn: 1 });
    setSelectedStartDate(weekStart);
    setSelectedEndDate(weekEnd);
    updateDisplayedDateRange(weekStart, weekEnd);
    setSelectedMonth(day);
  };

  const nextMonth = () => {
    const newDate = addMonths(currentDate, 1);
    if (!isBeyondCurrentMonth(newDate)) {
      setCurrentDate(newDate);
      setSelectedMonth(newDate);
    }
  };

  const prevMonth = () => {
    const newDate = subMonths(currentDate, 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate);
  };

  const nextYear = () => {
    const newDate = addYears(currentDate, 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate);
  };

  const prevYear = () => {
    const newDate = subYears(currentDate, 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate);
  };
  const isBeyondCurrentMonth = (date) => {
    const now = new Date();
    return (
      date.getFullYear() > now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() &&
        date.getMonth() > now.getMonth())
    );
  };

  function isNextMonthSelectable(maxSelectableDate) {
    const currentDate = new Date();
    const nextMonth = addMonths(currentDate, 1);
    const nextMonthStart = startOfMonth(nextMonth);

    return !isAfter(nextMonthStart, maxSelectableDate);
  }

  const header = () => {
    return (
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center space-x-4">
          <button onClick={prevMonth} className="text-gray-600 text-xl">
            <FaChevronLeft size={15} color="#333B48" />
          </button>
          <span className="text-lg font-bold">
            {format(currentDate, "MMM")}
          </span>
          <button
            onClick={nextMonth}
            className="text-gray-600 text-xl"
            disabled={isBeyondCurrentMonth(addMonths(currentDate, 1))}
          >
            <FaChevronRight color="#333B48" size={15} />
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-base font-medium">
            {format(currentDate, "yyyy")}
          </span>
          <div className="flex flex-col gap-3">
            <button
              onClick={nextYear}
              className="text-gray-600 text-xs"
              disabled={isAfter(addYears(currentDate, 1), maxSelectableDate)}
            >
              <CalendarGreyUpArrow />
            </button>
            <button onClick={prevYear} className="text-gray-600 text-xs">
              <CalendarGreydownArrow />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const daysOfWeek = () => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return (
      <div className="grid grid-cols-7 gap-1 mb-2 px-2">
        {days.map((day) => (
          <div
            key={day}
            className="text-center font-semibold text-sm text-gray-600"
          >
            {day}
          </div>
        ))}
      </div>
    );
  };
  const endOfCurrentWeek = endOfWeek(new Date(), { weekStartsOn: 1 });

  const isBeyondCurrentWeek = (date) => {
    return isAfter(date, endOfCurrentWeek);
  };

  const cells = () => {
    const monthStart = startOfMonth(selectedMonth);
    const monthEnd = endOfMonth(selectedMonth);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isSelected =
          selectedStartDate &&
          selectedEndDate &&
          isWithinInterval(cloneDay, {
            start: selectedStartDate,
            end: selectedEndDate,
          });
        const isRangeStart = isSameDay(cloneDay, selectedStartDate);
        const isRangeEnd = isSameDay(cloneDay, selectedEndDate);
        const isDisabled =
          !isSameMonth(day, selectedMonth) || isBeyondCurrentWeek(day);

        const cellClassNames = `p-0 text-center  calendar-design ${
          !isSameMonth(day, monthStart) ? "text-[#A1AEBF] text-sm font-300" : ""
        }
          ${isSelected ? "" : ""}
          ${isRangeStart || isRangeEnd ? " selected-range" : ""}
          ${
            isDisabled
              ? "text-[#A1AEBF] cursor-not-allowed text-sm font-300"
              : "cursor-pointer"
          }`;

        const cellSpanClassNames = `p-0 text-center calendar-design 
          ${
            !isSameMonth(day, monthStart)
              ? "text-[#A1AEBF] text-sm font-300"
              : ""
          }
          ${isSelected ? "bg-orange-100" : ""}
          ${
            isRangeStart || isRangeEnd
              ? "bg-orange-500 text-white rounded-full text-sm font-30 "
              : "text-[#515f6f] text-sm font-30"
          }
          ${
            isDisabled
              ? "text-[#A1AEBF] cursor-not-allowed text-sm font-30"
              : "cursor-pointer"
          }`;

        const cellStyle = { lineHeight: "2rem" };

        const cell = (
          <div
            className={cellClassNames}
            style={cellStyle}
            key={day}
            onClick={() => !isDisabled && onDateClick(cloneDay, isDisabled)}
          >
            <span className={`formated-date ${cellSpanClassNames} `}>
              {formattedDate}
            </span>
          </div>
        );

        days.push(cell);
        day = addDays(day, 1);
      }
      rows.push(
        <div className="grid grid-cols-7 my-selected-range" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="bg-white p-2 parent-cls">{rows}</div>;
  };

  const resetSelection = () => {
    const currentDate = new Date();

    const today2 = new Date();
    const lastMonday2 = startOfWeek(subDays(today2, 7), { weekStartsOn: 1 });
    const lastSaturday2 = endOfWeek(lastMonday2, { weekStartsOn: 1 });

    const weekStart = startOfWeek(subWeeks(currentDate, 1), {
      weekStartsOn: 1,
    });
    const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
    // New
    updateDisplayedDateRange(lastMonday2, lastSaturday2);
    setSelectedStartDate(lastMonday2);
    setSelectedEndDate(lastSaturday2);
    setCurrentDate(lastMonday2);
    setSelectedMonth(lastMonday2);
    setMaxSelectableDate(endOfMonth(lastMonday2));
    onReset(
      `${format(weekStart, "d MMM, yyyy")} - ${format(weekEnd, "d MMM, yyyy")}`
    );
    setIsCalendarOpen(false);
  };

  const applySelection = () => {
    setIsCalendarOpen(false);
    onDateRangeSelect(displayedDateRange);
    // Always move to the next month
    const newDate = addMonths(currentDate, 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate);
  };

  return (
    <>
      <div className="w-full max-w-[320px] mx-auto">
        {isCalendarOpen && (
          <div className="border rounded-lg shadow-md overflow-hidden bg-white">
            {header()}
            {daysOfWeek()}
            {cells()}
            <div className="flex justify-end p-4 border-t gap-4">
              <button
                onClick={resetSelection}
                className="text-black font-bold flex items-center"
              >
                <span className="mr-2">🗑</span> Reset
              </button>
              <button
                onClick={applySelection}
                className="text-orange-500 font-semibold"
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FinalDatePicker;
