// GlobalModal.js
import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import CustomButton from "./CustomButton";
import CustomDropdownCheckbox from "../../../components/global-components/CustomDropdownChekbox";
import { CrossIconGrey } from "../../icons";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "87%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 4,
};

const GlobalModal = ({
  isOpen,
  handleClose,
  dropdownOptions,
  onApply,
  left,
  top,
}) => {
  const [selectedValues, setSelectedValues] = useState({});

  const handleSelectionChange = (selectedValues, dropdownType) => {
    setSelectedValues((prev) => ({ ...prev, [dropdownType]: selectedValues }));
  };

  const handleResetClick = () => {
    setSelectedValues({});
  };

  const handleApplyClick = () => {
    onApply(selectedValues);
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box sx={{ ...ModalStyle, left: left, top: top }}>
        <div className="bg-white rounded-xl shadow-md mx-auto p-4">
          <div className="flex justify-between items-center pb-2">
            <p className="font-semibold">Filter by</p>
            <button onClick={handleClose} className="text-gray-600">
              <CrossIconGrey />
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {dropdownOptions.map((option, index) => (
              <div key={index} className="">
                <label
                  htmlFor={`${option.type}Dropdown`}
                  className="block text-base font-medium text-primary-6 pb-1"
                >
                  {option.label}
                </label>
                <CustomDropdownCheckbox
                  id={`${option.type}Dropdown`}
                  options={option.options}
                  onSelectionChange={handleSelectionChange}
                  dropdownType={option.type}
                  activeValues={selectedValues[option.type] || []}
                  defaultSelectAll={option.defaultSelectAll}
                />
              </div>
            ))}
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-between gap-4 mt-4">
            <CustomButton
              name="Reset"
              onClick={handleResetClick}
              className="w-full sm:w-40 border border-gray-300 rounded-lg h-8 text-black"
            />
            <CustomButton
              onClick={handleApplyClick}
              name="Apply"
              className="w-full sm:w-40 bg-primary-7 border border-gray-300 rounded-lg h-8 text-white"
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default GlobalModal;
