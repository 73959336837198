import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LocationIconWhite, Zoominicon, Zoomouticon } from "./icons";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { useSelector } from "react-redux";

const createCustomIcon = (imageUrl) => {
  return L.icon({
    iconUrl: imageUrl,
    iconSize: [25, 25],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });
};

const MapControls = ({ onZoomIn, onZoomOut, onFullscreen, isFullscreen }) => {
  return (
    <div className="absolute top-2 right-2 z-[1000] flex flex-row space-x-2">
      <button
        onClick={onZoomIn}
        className="p-2 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors"
      >
        <Zoominicon />
      </button>
      <button
        onClick={onZoomOut}
        className="p-2 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors"
      >
        <Zoomouticon />
      </button>
      <button
        onClick={onFullscreen}
        className="p-2 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors"
      >
        {isFullscreen ? <BsFullscreenExit /> : <BsArrowsFullscreen />}
      </button>
    </div>
  );
};

const CustomTooltip = ({ location }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      <LocationIconWhite />
      <h3 className="font-medium text-sm ">{location}</h3>
    </div>
  );
};

const Map = () => {
  const markerPositions = useSelector((state) => state.filter.markerPositions);

  const pointStatus = markerPositions.status;

  const [isFullscreen, setIsFullscreen] = useState(false);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      mapContainerRef.current.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else {
      document.exitFullscreen();
    }
  };

  const handleZoomIn = () => {
    if (mapRef.current) {
      mapRef.current.zoomIn();
    }
  };

  const handleZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.zoomOut();
    }
  };

  return (
    <div ref={mapContainerRef} className="w-full shadow-md h-full">
      <MapContainer
        center={
          markerPositions[0]
            ? [markerPositions[0].lat, markerPositions[0].lng]
            : [51.505, -0.09]
        }
        zoom={5}
        style={{ height: "100%", width: "100%" }}
        className="rounded-lg"
        ref={mapRef}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {markerPositions.map((position, index) => (
          <Marker
            key={index}
            position={[position.lat, position.lng]}
            icon={
              position.status === 1
                ? createCustomIcon("/Green-removebg-preview.png")
                : position.status === 2
                ? createCustomIcon("/Orange-removebg-preview.png")
                : createCustomIcon("/Grey-removebg-preview.png")
            }
          >
            <Tooltip direction="top" offset={[0, -20]} opacity={1}>
              <CustomTooltip
                location={position.location}
                status={position.status}
              />
            </Tooltip>
          </Marker>
        ))}
        <MapControls
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          onFullscreen={toggleFullscreen}
          isFullscreen={isFullscreen}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
