export const opscore = [
  { value: "0-10", label: "0-10" },
  { value: "20-30", label: "20-30" },
  { value: "30-40", label: "30-40" },
  { value: "40-50", label: "40-50" },
  { value: "50-60", label: "50-60" },
  { value: "60-70", label: "60-70" },
  { value: "70-80", label: "70-80" },
  { value: "80-90", label: "80-90" },
  { value: "90-100", label: "90-100" },
];

export const ratings = [
  { value: "0-0.5", label: "0-0.5" },
  { value: "0.5-1", label: "0.5-1" },
  { value: "1.0-1.5", label: "1.0-1.5" },
  { value: "1.5-2", label: "1.5-2" },
  { value: "2-2.5", label: "2-2.5" },
  { value: "2.5-3.0", label: "2.5-3.0" },
  { value: "3-3.5", label: "3-3.5" },
  { value: "3.5-4.0", label: "3.5-4.0" },
  { value: "4-4.5", label: "4-4.5" },
  { value: "4.5-5", label: "4.5-5" },
];

export const sales = [
  { value: "0-1", label: "0-1" },
  { value: "1-2", label: "1-2" },
  { value: "2-3", label: "2-3" },
  { value: "3-4", label: "3-4" },
  { value: "4-5", label: "4-5" },
  { value: "5-6", label: "5-6" },
  { value: "6-7", label: "6-7" },
  { value: "7-8", label: "7-8" },
  { value: "8-9", label: "8-9" },
  { value: "9-10", label: "9-10" },
];

export const status = [
  { value: 1, label: "Active" },
  { value: 2, label: "Trial" },
  { value: 3, label: "Churned" },
];

export const customerReviews = [
  {
    value: "deliveroo",
    label: "Deliveroo",
  },
  {
    value: "Justeat",
    label: "JustEat",
  },
  {
    value: "Ubereats",
    label: "Uber Eats",
  },
];
