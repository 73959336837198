import React from "react";

export default function ControlOverview() {
  return (
    <div className="flex justify-center items-center w-full h-[60vh] flex-col">
      <img
        src={`./under-construction.png`}
        alt={"under-construction"}
        className="w-[15rem] h-[15rem]"
      />
      <h2 className="font-[600] text-[20px]">Under Construction</h2>
    </div>
  );
}
