import React, { useState } from "react";
import { MarketingIcon } from "../../../components/icons";
import Button from "../../../components/global-components/Button";
import Overview from "../components/Overview/Overview";
import Adverts from "../components/Adverts/Adverts";
import Offers from "../components/Offers/Offers";
import OverviewDatePicker from "../components/Overview/OverviewDatePicker";
import OverviewAddFilter from "../components/Overview/OverviewAddFilter";

const Marketing = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  return (
    <>
      <div>
        <div className="flex flex-col sm:flex-row items-center gap-4 w-full">
          <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full bg-gray-900">
            <span className="font-medium text-gray-600">
              <MarketingIcon />
            </span>
          </div>

          <div className="flex flex-col flex-grow">
            <span className="text-lg text-primary-7 font-medium Product_Sans_Medium">
              Marketing
            </span>
            <span className="text-base font-medium text-primary-5 ">
              View and manage all your active and upcoming kitchen locations
              with ease
            </span>
          </div>

          {/* <div className="flex items-end -mt-4">
            <Button />
          </div> */}
        </div>

        <div className="flex flex-row items-center justify-between w-full mb-8 mt-8 ">
          <div className="flex space-x-8 leading-[1.8]">
            {["Overview", "Adverts", "Offers"].map((tab) => (
              <button
                key={tab}
                className={`${
                  activeTab === tab
                    ? "border-b-[3.4px] border-black text-xl font-extrabold tracking-wider"
                    : "text-[#A1AEBF] text-xl Product_Sans_Medium font-medium "
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="flex space-x-4">
            <div className="text-xl text-primary-7 font-medium">
              <OverviewDatePicker />
            </div>
            <div className="text-xl text-primary-7 font-medium">
              <OverviewAddFilter />
            </div>
          </div>
        </div>

        {activeTab === "Overview" && <Overview />}
        {activeTab === "Adverts" && <Adverts />}
        {activeTab === "Offers" && <Offers />}
      </div>
    </>
  );
};

export default Marketing;
