import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const myToken = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    if (!myToken) {
      navigate("/");
    }
  }, [myToken]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // return (
  //   <div className="flex h-screen bg-gray-100 w-full">
  //     <div className={`w-2/12 bg-red-500`}>
  //       <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
  //     </div>
  //     <div className={`w-[100%] flex flex-col`}>
  //       <main className={`flex-1 bg-[#fcfdff]`}>
  //         {/* ${isOpen ? "ml-[235px]" : "ml-[80px]"} */}
  //         <div className="container ">
  //           <Topbar />
  //           <div className="mx-auto px-6 py-8">{children}</div>
  //         </div>
  //       </main>
  //     </div>
  //   </div>
  // );

  const sidebarWidth = isOpen ? "20%" : "5%";
  const mainContainerWidth = isOpen ? "80%" : "95%";

  return (
    <>
      <div className="layout-container">
        <div
          className={`  sidebar-container `}
          style={{ minWidth: sidebarWidth }}
        >
          <Sidebar
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
            sidebarWidth={sidebarWidth}
          />
        </div>
        <main
          className={` main-container `}
          style={{ minWidth: mainContainerWidth }}
        >
          <Topbar />
          <div className="mx-auto px-6 py-8">{children}</div>
        </main>
      </div>
    </>
  );
};

export default Layout;
