import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Line } from "react-chartjs-2";
import { Growthicon } from "../../../components/icons";

const DailyOperationalScore = ({ weeklyLoading }) => {
  const chartData = {
    labels: [
      "Week 1",
      "Week 2",
      "Week 3",
      "Week 4",
      "Week 5",
      "Week 6",
      "Week 7",
      "Week 8",
    ],
    datasets: [
      {
        label: "Score",
        data: [20, 25, 30, 28, 22, 27, 30, 26], // Example data
        borderColor: "#10B981",
        borderWidth: 1,
        pointRadius: 5,
        pointBorderWidth: 0,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        tension: 0,
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0.1, "rgba(23, 224, 128, 0.2)");
          gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
          return gradient;
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw;
            return `${label}: ${value}`;
          },
        },
      },
    },
    scales: {
      y: {
        display: false,
        min: 10,
      },
      x: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-md flex flex-col h-full">
      {weeklyLoading ? (
        <div className="px-4 pt-2 pb-0">
          <Skeleton height={20} width={150} />
          <Skeleton height={20} width={50} />
          <Skeleton height={100} />
        </div>
      ) : (
        <>
          <div className="px-4 pt-2 pb-0">
            <p className="text-md font-medium text-primary-5">
              Trending operational score
            </p>
          </div>
          <div className="flex items-center gap-1 px-4">
            <span className="text-xl font-bold">25.00%</span>
            <Growthicon /> {/* or Declineicon depending on your design */}
            <span className="text-green-500 font-semibold text-xs">
              +5.00% vs. last week
            </span>
          </div>
          <div className="flex-grow overflow-hidden">
            <Line data={chartData} options={options} />
          </div>
        </>
      )}
    </div>
  );
};

export default DailyOperationalScore;
