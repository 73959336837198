import React, { useEffect, useState } from "react";

const CustomCheckbox = ({
  label,
  checked: initialChecked = false,
  onChange,
  size = 17,
  borderColor = "#A1AEBF",
  checkedColor = "#FD8E32",
  hoverColor = "#E0E0E0",
  checkColor = "#FFFFFF",
  borderRadius = 4,
  labelColor = "#000000",
  labelFont = "inherit",
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(initialChecked);

  const handleToggle = () => {
    if (!disabled) {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      onChange && onChange(newChecked);
    }
  };

  const CheckIcon = ({ size, color }) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );

  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);

  return (
    <div
      className="flex items-center cursor-pointer select-none"
      onClick={handleToggle}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <div
        className="relative flex items-center justify-center"
        style={{
          width: size,
          height: size,
          borderRadius: borderRadius,
          border: `1.6px solid ${borderColor}`,
          backgroundColor: isChecked ? checkedColor : "transparent",
          borderColor: isChecked ? "#FD8E32" : "#A1AEBF",
          transition: "all 0.2s",
        }}
      >
        {isChecked && <CheckIcon size={size * 0.75} color={checkColor} />}
        <div
          className="absolute inset-0 hover:bg-opacity-20 transition-colors duration-200"
          style={{
            borderRadius: borderRadius,
            backgroundColor: hoverColor,
            opacity: disabled ? 0 : 0.1,
          }}
        />
      </div>
      {label && (
        <span
          className="ml-2"
          style={{
            color: labelColor,
            font: labelFont,
          }}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default CustomCheckbox;
