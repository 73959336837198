import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDates: "",
  isLoading: true,
};

// Create a slice of the Redux store
const dailyOperations = createSlice({
  name: "dailyOperations",
  initialState,
  reducers: {
    setSelectedDatesdailyOp: (state, action) => {
      const { dates } = action.payload;

      state.selectedDates = dates;
      state.isLoading = false;
    },
  },
});

export const { setSelectedDatesdailyOp } = dailyOperations.actions;
export default dailyOperations.reducer;
