import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow } from "./CustomArrow";
import { UberEats, JustEat, Deliveroo } from "../icons";
import "../../App.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ResNameList = {
  ubereats: {
    name: "Uber Eats",
    logo: <UberEats />,
  },
  justeat: {
    name: "JustEat",
    logo: <JustEat />,
  },
  deliveroo: {
    name: "Deliveroo",
    logo: <Deliveroo />,
  },
};

const MultiCardCarousel = ({ reviewData, loading, handleAfterChange }) => {
  console.log("MultiCardCarousel", reviewData);
  const settings = (hasData) => ({
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: hasData ? <NextArrow /> : null,
    prevArrow: hasData ? <PrevArrow /> : null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: handleAfterChange,
  });

  const [expandedIndex, setExpandedIndex] = useState(null);

  const sliderRef = useRef(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const renderStars = (count) => (
    <>
      {[...Array(count)].map((star, i) => (
        <svg
          key={i}
          className="w-5 h-5 text-yellow-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
        </svg>
      ))}
      {[...Array(5 - count)].map((star, i) => (
        <svg
          key={i}
          className="w-5 h-5 text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
        </svg>
      ))}
    </>
  );

  const renderReview = (
    review,
    index,
    platform,
    ratingField,
    commentField,
    dateField
  ) => (
    <div key={index} className="p-4">
      <div className="bg-white rounded-lg">
        <div className="flex items-center mb-4">
          <div className="w-14 h-10">
            {ResNameList[review[platform].trim().toLowerCase()].logo}
          </div>
          <div className="flex justify-between w-full">
            <div>
              <h3 className="text-[0.9rem] font-[600]">
                {/* {review[platform]} */}
                {ResNameList[review[platform].trim().toLowerCase()].name}
              </h3>
              <div className="flex items-center mb-[0.8rem]">
                {renderStars(parseInt(review[ratingField] || 0))}
              </div>
            </div>
            <div className="text-sm text-gray-600">{review[dateField]}</div>
          </div>
        </div>
        <p
          className={`text-gray-700 ${
            expandedIndex === index ? "line-clamp-none" : "line-clamp-3"
          }`}
        >
          {review[commentField]}
        </p>

        {review[commentField]?.length > 110 && (
          <div className="w-[100%] text-end">
            <button
              onClick={() => toggleExpand(index)}
              className="text-orange-500 font-[500]"
            >
              {expandedIndex === index ? "Read less" : "Read more"}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const hasData = reviewData?.length > 0 || false;

  return (
    <div className="max-w-7xl mx-auto">
      {loading ? (
        <Slider {...settings(true)}>
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="p-4">
              <div className="bg-white rounded-lg">
                <div className="flex items-center mb-4">
                  <Skeleton circle={true} width={48} height={48} />
                  <div className="flex justify-between w-full">
                    <div>
                      <Skeleton width={120} />
                      <div className="flex items-center mb-[0.8rem]">
                        <Skeleton width={120} />
                      </div>
                    </div>
                    <Skeleton width={80} />
                  </div>
                </div>
                <Skeleton count={5} />
              </div>
            </div>
          ))}
        </Slider>
      ) : hasData ? (
        <Slider {...settings(hasData)}>
          {reviewData &&
            reviewData?.map((review, index) =>
              renderReview(
                review,
                index,
                "name",
                "Rating_average",
                "Commentary",
                "Day_order_date"
              )
            )}
        </Slider>
      ) : (
        <div className="flex justify-center items-center flex-col p-8 font-[500]">
          <div className="flex">{renderStars(0)}</div>
          No review available
        </div>
      )}
    </div>
  );
};

export default MultiCardCarousel;
