import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Growthicon, Declineicon } from "../../../components/icons";

const DailyAverageRating = ({ weeklyLoading, getDailyRatingData }) => {
  // Extract rating data from getDailyRatingData
  const rating = getDailyRatingData?.data?.rating;

  // If rating is undefined, initialize an empty array or handle accordingly
  const ratingArray = rating
    ? [
        {
          img: "justeat.png",
          name: "JustEat",
          ratings: parseFloat(rating.justeat.rating),
          description: rating.justeat.count.toString(),
        },
        {
          img: "deliveroo.png",
          name: "Deliveroo",
          ratings: parseFloat(rating.deliveroo.rating),
          description: rating.deliveroo.count.toString(),
        },
        {
          img: "ubereats.png",
          name: "Uber Eats",
          ratings: parseFloat(rating.uber.rating),
          description: rating.uber.count.toString(),
        },
      ]
    : [];

  const isGrowth = true; // Example static value
  const percentageChange = 5.0; // Example static value

  const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const remainder = rating % 1;
    let halfStarOffset = 0;

    if (remainder >= 0.9) halfStarOffset = 90;
    else if (remainder >= 0.8) halfStarOffset = 80;
    else if (remainder >= 0.7) halfStarOffset = 70;
    else if (remainder >= 0.6) halfStarOffset = 60;
    else if (remainder >= 0.5) halfStarOffset = 50;

    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, index) => {
          if (index < fullStars) {
            return (
              <svg
                key={index}
                className="w-4 h-4 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
              </svg>
            );
          } else if (index === fullStars && halfStarOffset > 0) {
            return (
              <svg
                key={index}
                className="w-4 h-4 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <defs>
                  <linearGradient id="halfStarGradient">
                    <stop offset={`${halfStarOffset}%`} stopColor="#FACC15" />
                    <stop offset={`${halfStarOffset}%`} stopColor="#D1D5DB" />
                  </linearGradient>
                </defs>
                <path
                  fill="url(#halfStarGradient)"
                  d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z"
                />
              </svg>
            );
          } else {
            return (
              <svg
                key={index}
                className="w-4 h-4 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
              </svg>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="bg-white px-4 py-2 rounded-lg shadow-md flex flex-col h-full">
      {weeklyLoading ? (
        <div className="flex items-center space-x-2 mb-6">
          <Skeleton width={40} height={24} />
          <Skeleton width={100} height={10} />
        </div>
      ) : (
        <>
          <h3 className="text-md font-medium text-primary-5">
            Average Daily Rating
          </h3>
          <div className="flex items-center space-x-2 mb-[1rem] mt-[6px]">
            <p className="text-xl font-bold">
              {getDailyRatingData?.data?.rating?.overAllRating?.rating}
            </p>
            <svg
              className="w-4 h-4 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
            </svg>
          </div>
        </>
      )}
      <div className="space-y-3 flex-grow overflow-y-auto">
        {weeklyLoading ? (
          <>
            {[...Array(3)].map((_, index) => (
              <div key={index} className="flex items-center">
                <Skeleton
                  circle={true}
                  width={20}
                  height={20}
                  className="mr-4"
                />
                <Skeleton width={100} />
                <div className="flex items-center ml-auto">
                  <Skeleton width={30} height={24} className="mr-2" />
                  {[...Array(5)].map((_, i) => (
                    <Skeleton key={i} width={20} height={20} className="mr-1" />
                  ))}
                  <Skeleton width={30} height={24} className="ml-2" />
                </div>
              </div>
            ))}
          </>
        ) : (
          ratingArray?.map((item, index) => (
            <div key={index} className="flex items-center">
              <img
                src={`./${item.img}`}
                alt={item.name}
                className="w-8 h-8 mr-2"
              />
              <span className="text-base flex-1" style={{ fontWeight: "600" }}>
                {item?.name}
              </span>
              <div className="flex items-center justify-between gap-x-2">
                <span className="text-sm font-bold text-gray-900">
                  {item?.ratings.toFixed(1)}
                </span>
                <StarRating rating={item?.ratings} />
                <span className="text-gray-500 text-sm w-8 text-right">
                  {item?.description}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DailyAverageRating;
