import React, { useState } from "react";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import { LiaLockOpenSolid } from "react-icons/lia"; // Import both lock icons
import { toast, ToastContainer } from "react-toastify";
import { useCallPostMutation } from "../../services/api";
import "react-toastify/dist/ReactToastify.css";
import { GrowthIconWhite } from "../../components/icons";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const [postData, { isLoading, isError }] = useCallPostMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Please fill in both password fields.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    setLoading(true);

    try {
      const response = await postData({
        url: `resetpassword?token=${token}`,
        body: { password },
      }).unwrap();

      if (response?.status === true) {
        toast.success("Password reset successfully.", { autoClose: 2000 });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        toast.error("Password reset failed. Please try again.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <section className="h-screen bg-white flex">
        <div className="relative w-[50%] h-screen bg-[#022c19]">
          <div className="absolute top-0 left-0 right-0 h-1/3 bg-gradient-to-b from-[#00FF85] to-[#17E080] opacity-30 rounded-full transform -translate-y-1/3 blur-3xl"></div>

          <img
            src="/burger.jpeg"
            alt="Burger"
            className="h-full w-full object-cover opacity-50"
          />

          {/* Text overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <GrowthIconWhite />
          </div>
        </div>

        {/* Reset Password form */}
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <div className="w-full max-w-md mt-5">
            <img src="/gk-logo.jpeg" alt="Logo" className="mx-auto h-28 w-28" />

            <p className="m-6 font-semibold text-3xl text-center">
              Reset Password
            </p>

            <form onSubmit={handleSubmit}>
              <div className="mb-4 ml-20">
                <label className="block text-primary-6 text-sm mb-2 Product_Sans_Medium font-[400]">
                  New Password <span className="text-red-500">*</span>
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  className="custom-textfield"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your new password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  error={isError && !password.trim()}
                  helperText={
                    isError && !password.trim() ? "Please enter password" : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          style={{ marginRight: "-8px" }}
                        >
                          {showPassword ? (
                            <LiaLockOpenSolid size={20} />
                          ) : (
                            <svg
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5.25H10.25V4C10.25 3.13805 9.90759 2.3114 9.2981 1.7019C8.6886 1.09241 7.86195 0.75 7 0.75C6.13805 0.75 5.3114 1.09241 4.7019 1.7019C4.09241 2.3114 3.75 3.13805 3.75 4V5.25H2C1.66848 5.25 1.35054 5.3817 1.11612 5.61612C0.881696 5.85054 0.75 6.16848 0.75 6.5V13.5C0.75 13.8315 0.881696 14.1495 1.11612 14.3839C1.35054 14.6183 1.66848 14.75 2 14.75H12C12.3315 14.75 12.6495 14.6183 12.8839 14.3839C13.1183 14.1495 13.25 13.8315 13.25 13.5V6.5C13.25 6.16848 13.1183 5.85054 12.8839 5.61612C12.6495 5.3817 12.3315 5.25 12 5.25ZM5.25 4C5.25 3.53587 5.43437 3.09075 5.76256 2.76256C6.09075 2.43437 6.53587 2.25 7 2.25C7.46413 2.25 7.90925 2.43437 8.23744 2.76256C8.56563 3.09075 8.75 3.53587 8.75 4V5.25H5.25V4ZM11.75 13.25H2.25V6.75H11.75V13.25Z"
                                fill="#515F6F"
                              />
                            </svg>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                      width: "320px",
                      "& fieldset": {
                        borderColor: "#A1AEBF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#A1AEBF",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#A1AEBF",
                      },
                    },
                    "& .MuiInputAdornment-root": {
                      marginRight: "-5px",
                    },
                  }}
                />
              </div>

              <div className="mb-4">
                <label className="block text-primary-6 text-sm mb-2 Product_Sans_Medium font-[400]">
                  Confirm Password <span className="text-red-500">*</span>
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="custom-textfield"
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm your new password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  error={isError && !confirmPassword.trim()}
                  helperText={
                    isError && !confirmPassword.trim()
                      ? "Please enter password"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          style={{ marginRight: "-8px" }}
                        >
                          {showPassword ? (
                            <LiaLockOpenSolid size={20} />
                          ) : (
                            <svg
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5.25H10.25V4C10.25 3.13805 9.90759 2.3114 9.2981 1.7019C8.6886 1.09241 7.86195 0.75 7 0.75C6.13805 0.75 5.3114 1.09241 4.7019 1.7019C4.09241 2.3114 3.75 3.13805 3.75 4V5.25H2C1.66848 5.25 1.35054 5.3817 1.11612 5.61612C0.881696 5.85054 0.75 6.16848 0.75 6.5V13.5C0.75 13.8315 0.881696 14.1495 1.11612 14.3839C1.35054 14.6183 1.66848 14.75 2 14.75H12C12.3315 14.75 12.6495 14.6183 12.8839 14.3839C13.1183 14.1495 13.25 13.8315 13.25 13.5V6.5C13.25 6.16848 13.1183 5.85054 12.8839 5.61612C12.6495 5.3817 12.3315 5.25 12 5.25ZM5.25 4C5.25 3.53587 5.43437 3.09075 5.76256 2.76256C6.09075 2.43437 6.53587 2.25 7 2.25C7.46413 2.25 7.90925 2.43437 8.23744 2.76256C8.56563 3.09075 8.75 3.53587 8.75 4V5.25H5.25V4ZM11.75 13.25H2.25V6.75H11.75V13.25Z"
                                fill="#515F6F"
                              />
                            </svg>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                      width: "320px",
                      "& fieldset": {
                        borderColor: "#A1AEBF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#A1AEBF",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#A1AEBF",
                      },
                    },
                    "& .MuiInputAdornment-root": {
                      marginRight: "-5px",
                    },
                  }}
                />
              </div>
              <div className="w-full flex items-end justify-end pr-12">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: "#061629",
                    borderRadius: "8px",
                    padding: "12px 16px",
                    marginLeft: "16px",
                    width: "auto",
                    height: "40px",
                  }}
                  disabled={loading || isLoading}
                >
                  {loading || isLoading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    <span
                      className="Product_Sans_Medium font-[500]"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        textTransform: "none",
                      }}
                    >
                      Reset Password
                      <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "8px" }}
                      >
                        <path
                          d="M13.0306 6.03122L8.53063 10.5312C8.38973 10.6721 8.19863 10.7513 7.99938 10.7513C7.80012 10.7513 7.60902 10.6721 7.46813 10.5312C7.32723 10.3903 7.24807 10.1992 7.24807 9.99997C7.24807 9.80072 7.32723 9.60962 7.46813 9.46872L10.6875 6.2506H1.5C1.30109 6.2506 1.11032 6.17158 0.96967 6.03093C0.829018 5.89028 0.75 5.69951 0.75 5.5006C0.75 5.30169 0.829018 5.11092 0.96967 4.97027C1.11032 4.82962 1.30109 4.7506 1.5 4.7506H10.6875L7.46937 1.5306C7.32848 1.3897 7.24932 1.19861 7.24932 0.999348C7.24932 0.80009 7.32848 0.608994 7.46937 0.468098C7.61027 0.327201 7.80137 0.248047 8.00062 0.248047C8.19988 0.248047 8.39098 0.327201 8.53187 0.468098L13.0319 4.9681C13.1018 5.03787 13.1573 5.12077 13.1951 5.21203C13.2329 5.3033 13.2523 5.40113 13.2522 5.49992C13.252 5.5987 13.2324 5.69649 13.1944 5.78767C13.1564 5.87885 13.1007 5.96162 13.0306 6.03122Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  )}
                </Button>
              </div>
              <p className="mt-4 text-center">
                Already have an account?{" "}
                <Link
                  component={RouterLink}
                  to="/"
                  className="no-underline Product_Sans_Medium"
                  style={{
                    textDecoration: "none",
                    color: "#FD7200",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Sign in
                </Link>
              </p>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
