import React, { useState, useMemo } from "react";
import { Star, Eye } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { SadEmoji } from "./icons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  handleDateChange,
  setSelectedLocation,
  setweeklyOperationsData,
} from "../redux/slices/filter-slice";
import { ArrowDown, ArrowUp } from "lucide-react";

const globalStatus = {
  1: "Active",
  2: "Trial",
  3: "Churned",
};

export const Table = ({ tableData, loading }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const primary6 = "#515F6F";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationDate = useSelector((state) => state?.filter?.locationDates);

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });

    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/^£/, "£ ");
    return formattedAmount;
  };

  const handleClickNavigate = (location, SelectedData) => {
    dispatch(
      handleDateChange({
        id: "weeklyDates",
        startDate: SelectedData.start,
        endDate: SelectedData.end,
      })
    );
    dispatch(setweeklyOperationsData([location.location]));
    dispatch(setSelectedLocation([location.location]));

    // navigate(`/weekly-operations?redirect=true&rating=${location?.rating}`);
    navigate(`/weekly-operations?redirect=true`);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedTableData = useMemo(() => {
    const sortableData = [...tableData];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (sortConfig.key === "weekly_sales") {
          // Convert sales to numbers for numeric sorting
          const aSales = parseFloat(a[sortConfig.key].replace(/£|,/g, ""));
          const bSales = parseFloat(b[sortConfig.key].replace(/£|,/g, ""));
          return sortConfig.direction === "asc"
            ? aSales - bSales
            : bSales - aSales;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableData;
  }, [tableData, sortConfig]);

  const renderTableContent = () => {
    if (loading) {
      return Array.from({ length: 10 }).map((_, index) => (
        <tr key={index} className="bg-white border-b hover:bg-gray-50">
          <td className="px-2 sm:px-4 py-2 sm:py-3 w-10">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3">
            <Skeleton circle width={20} height={20} />
          </td>
        </tr>
      ));
    }

    if (loading && sortedTableData.length === 0) {
      return (
        <tr className="bg-gray-50">
          <td colSpan="6" className="px-2 sm:px-4 py-12 sm:py-24 text-center">
            <div className="flex flex-col items-center justify-center">
              <SadEmoji />
              <p className="text-gray-900 font-semibold text-lg sm:text-xl">
                No records found
              </p>
              <p className="text-gray-500 mt-2 text-sm sm:text-base">
                Try adjusting your search or filter to find what you're looking
                for.
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return sortedTableData.map((row, index) => (
      <tr key={index} className="bg-white border-t border-b hover:bg-gray-50 ">
        <td
          className={`truncate-text px-2 sm:px-4 py-2 sm:py-3 text-primary-7 text-base font-normal ${
            parseInt(row?.status) === 2
              ? "text-orange-600"
              : parseInt(row?.status) === 1
              ? "text-primary-7"
              : "text-primary-6"
          }`}
          data-tooltip-id="location-tooltip" // Changed attribute
          data-tooltip-content={row?.location} // Changed attribute
        >
          <div
            className=" justify-center gap-2 cursor-pointer"
            onClick={() => {
              handleClickNavigate(row, {
                start: locationDate?.start,
                end: locationDate?.end,
              });
            }}
          >
            {row?.location}
          </div>
        </td>

        <td
          className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell text-primary-7 text-base font-normal"
          data-tooltip-id="Weekly-tooltip" // Changed attribute
          data-tooltip-content={`Overall Sales: ${row?.weekly_sales}`} // Changed attribut
        >
          {formatCurrency(row?.weekly_sales)}
        </td>
        <td
          className={`px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell ${
            parseInt(row?.status) === 2
              ? "text-orange-600 text-base font-medium"
              : parseInt(row?.status) === 1
              ? `text-[#12B366] text-base font-medium`
              : `text-${primary6} text-base font-medium`
          }`}
        >
          {globalStatus[row?.status]}
        </td>
        <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell text-base font-medium ">
          <div
            className={`${
              row?.rating >= 4.4
                ? "rounded-oval"
                : row?.rating >= 4 && row?.rating < 4.4
                ? "rounded-oval-blue"
                : "rounded-oval-red"
            }`}
          >
            {row?.rating !== undefined &&
            row?.rating !== "" &&
            !isNaN(row?.rating)
              ? parseFloat(row?.rating).toFixed(1)
              : "0.0"}
            {row?.rating !== "-" && (
              <Star
                size={18}
                className="font-semibold bold-star-icon"
                color={
                  row?.rating >= 4.4
                    ? "#12B366" // Green color
                    : row?.rating >= 4 && row?.rating < 4.4
                    ? "#059CDF" // Blue color
                    : "#B30625" // Red color
                }
              />
            )}
          </div>
        </td>
        <td className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell">
          <div
            className={`text-base font-medium ${
              Number(row?.op_score) >= 75
                ? "rounded-oval"
                : Number(row?.op_score) >= 50 && Number(row?.op_score) < 75
                ? "rounded-oval-blue"
                : "rounded-oval-red"
            }`}
          >
            {parseInt(row?.op_score) + "%"}
          </div>
        </td>
        <td className="px-2 sm:px-4 py-2 sm:py-3">
          <button
            className=" justify-center gap-2"
            onClick={() => {
              handleClickNavigate(row, {
                start: locationDate?.start,
                end: locationDate?.end,
              });
            }}
          >
            <span className="text-primary-7 text-sm font-semibold hidden sm:inline hover:underline">
              View
            </span>
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="w-full overflow-x-auto max-h-[500px] custom-scrollbar">
      <table className="table-fixed min-w-full divide-y divide-gray-200 sticky top-0 ">
        <thead className="bg-primary-2 sticky top-0 ">
          <tr className="">
            <th
              onClick={() => handleSort("location")}
              className="cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md  relative  text-primary-6  text-base font-medium "
            >
              Location
              {sortConfig.key === "location" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ))}
            </th>

            <th
              onClick={() => handleSort("weekly_sales")}
              className="cursor-pointer px-5 py-3  bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Weekly Sales
              {sortConfig.key === "weekly_sales" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-6"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-6"
                    size={15}
                  />
                ))}
            </th>

            <th
              onClick={() => handleSort("status")}
              className=" cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Status
              {sortConfig.key === "status" && (
                <>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => handleSort("rating")}
              className="cursor-pointer   px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Rating
              {sortConfig.key === "rating" && (
                <span>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  )}
                </span>
              )}
            </th>
            <th
              onClick={() => handleSort("op_score")}
              className=" cursor-pointer  px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              OP Score
              {sortConfig.key === "op_score" && (
                <span>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-6"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-6"
                      size={15}
                    />
                  )}
                </span>
              )}
            </th>
            <th className="cursor-pointer  px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium">
              <Eye size={16} />
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTableContent()}
          <Tooltip id="location-tooltip" />
          <Tooltip id="Weekly-tooltip" />
        </tbody>
      </table>
    </div>
  );
};
