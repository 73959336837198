import React from "react";
import Toggle from "../../../../components/shared/Toggle";
import CustomDropdownCheckbox from "../../../../components/global-components/CustomDropdownChekbox";
import { Motorcycle } from "../../../../components/icons";
import Linechart from "./LineChart";

const LineChart = () => {
  const handleToggle = (toggled, id) => {};

  return (
    <>
      <div className="bg-white p-4 ">
        <div className="flex items-center justify-between">
          <p className="text-xl font-semibold">Marketing revenue performance</p>

          <div className="flex items-center space-x-4">
            <Toggle label="Compare" onToggle={handleToggle} id="toggle1" />
            <CustomDropdownCheckbox />
            <div className="flex items-center space-x-2">
              <Motorcycle />
              <p className="text-primary-6 Product_Sans_Medium text-base font_medium">
                Delivery app
              </p>
            </div>
            <CustomDropdownCheckbox />
          </div>
        </div>

        <div className="mt-4 flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2  bg-blue-5 rounded-full"></div>
            <p className="text-base text-primary-6">Net revenue</p>
          </div>

          <div className="flex items-center space-x-2">
            <div className="w-2 h-2  bg-[#A500FF] rounded-full "></div>
            <p className="text-base text-primary-6">Gross revenue</p>
          </div>
        </div>

        <div>
          <Linechart />
        </div>
      </div>
    </>
  );
};

export default LineChart;
