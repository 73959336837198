import React from "react";
import {
  LeftGrayArrowIcon,
  LeftBlackArrowIcon,
  RightBlackArrowIcon,
  RightGrayArrowIcon,
} from "../icons";

const Pagination = ({
  totalPages,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const startRange = (currentPage - 1) * itemsPerPage + 1;
  const endRange = Math.min(
    currentPage * itemsPerPage,
    totalPages * itemsPerPage
  );

  // Handle "All" items per page
  const effectiveItemsPerPage =
    itemsPerPage === 0 ? totalPages * 10 : itemsPerPage; // Adjust multiplier as needed

  const selectStyles = {
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    paddingRight: "2rem",
  };

  return (
    <div className="flex flex-col items-center mt-2">
      <div className="flex items-center mb-2 space-x-4">
        <div className="flex items-center relative">
          <select
            value={itemsPerPage}
            onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
            className="border-2 border-[#A1AEBF] rounded-lg shadow-sm px-[10.7px] py-2 h-10 focus:outline-none w-32"
            aria-label="Items per page"
            style={selectStyles}
          >
            {[20, 50, 0].map((value) => (
              <option key={value} value={value}>
                {value === 0 ? "All" : `${value} per page`}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
          <span className="ml-3 absolute bg-white">
            {itemsPerPage === 0 ? (
              <span className="font-bold text-black">All</span>
            ) : (
              <>
                <span className="font-bold text-black">{itemsPerPage}</span>{" "}
                <span className="text-gray-500">per page</span>
              </>
            )}
          </span>
        </div>
        <div className="flex items-center px-3 py-2 h-10 bg-white border-2 border-[#A1AEBF] text-gray-500 rounded-lg shadow-sm">
          <span className="mr-2">
            <span className="text-black font-medium">
              {startRange}-{endRange}
            </span>{" "}
            of {totalPages * effectiveItemsPerPage}
          </span>
          <div className="flex space-x-1 border-l border-gray-300 h-10">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className={`px-1 ${
                currentPage === 1 ? "text-gray-300" : "text-black"
              }`}
              disabled={currentPage === 1}
              aria-label="Previous Page"
            >
              {currentPage === 1 ? (
                <LeftGrayArrowIcon />
              ) : (
                <LeftBlackArrowIcon />
              )}
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className={`px-1 ${
                currentPage === totalPages ? "text-gray-300" : "text-black"
              }`}
              disabled={currentPage === totalPages}
              aria-label="Next Page"
            >
              {currentPage === totalPages || totalPages === 0 ? (
                <RightGrayArrowIcon />
              ) : (
                <RightBlackArrowIcon />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
