import React from "react";
import { Growthicon } from "../../../../components/icons";

const AdvertsAllocationBudget = () => {
  return (
    <div className="p-4 bg-white rounded-lg border border-primary-4">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex gap-x-2">
            <p className="text-primary-6">Allocation budget</p>
            <p className="font-semibold">£24,562.50</p>
          </div>
          <div className="flex gap-x-2">
            <p className="text-primary-6">Spent</p>
            <p className="font-semibold">£24,562.50</p>
            <p className="text-primary-6">(106%)</p>
            <p className="text-primary-6">Overbudget</p>
            <p className="font-semibold">£24,562.50</p>
          </div>
        </div>

        <div className="w-full bg-[#FF3559] rounded-full h-[5.9px]">
          <div
            className="bg-[#059CD5] h-[5.9px] rounded-full"
            style={{ width: "85%" }}
          ></div>
        </div>

        <div className="flex items-center justify-between">
          {[
            "New customer acquired",
            "Percentage of budget spent",
            "Average bid",
            "Return of ad spent",
            "Conversion rate",
          ].map((title, index) => (
            <>
              {index > 0 && (
                // <div className="flex items-center gap-x-4">
                <div className="bg-[#E8EFF6] h-14 w-[1.5px]"></div>
                // </div>
              )}
              <div className="">
                <p className="text-primary-5 Product_Sans_Medium font-[400]">
                  {title}
                </p>
                <div className="flex items-center gap-x-2">
                  <p className="text-primary-7">1,221</p>
                  <Growthicon />
                  <p className="text-green-6 font-bold">+8 vs. last week</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvertsAllocationBudget;
