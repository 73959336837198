import React from "react";
import { Routes, Route } from "react-router-dom";
import Locations from "../pages/Locations/locations";
import WeeklyOperations from "../pages/Weekly-Operations/WeeklyOperations";
import Layout from "../components/layouts/Layout";
import Marketing from "../domain/Marketing/pages/Marketing";
import DailyOperations from "../domain/DailyOperations/pages/DailyOperations";

const PagesRoutes = () => (
  <>
    <Routes>
      <Route
        exact
        path="/locations"
        element={
          <Layout>
            <Locations />
          </Layout>
        }
      />
      <Route
        exact
        path="/daily-operations"
        element={
          <Layout>
            <DailyOperations />
          </Layout>
        }
      />
      <Route
        exact
        path="/weekly-operations"
        element={
          <Layout>
            <WeeklyOperations />
          </Layout>
        }
      />
      <Route
        exact
        path="/marketing"
        element={
          <Layout>
            <Marketing />
          </Layout>
        }
      />
    </Routes>
  </>
);

export default PagesRoutes;
