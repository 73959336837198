import { configureStore } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import filterSlice from "../slices/filter-slice";
import dailyOperationsReducer from "../../domain/DailyOperations/slice/DailyOperationSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    filter: filterSlice,
    dailyOperations: dailyOperationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
