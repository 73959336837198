import React, { useState, useEffect } from "react";

const SearchBar = ({
  placeholder = "Search",
  minLength = 3,
  debounceTime = 300,
  onSearch = () => {},
  width = "w-72",
  height = "h-10",
  borderColor = "border-[#A1AEBF]",
  textColor = "text-gray-500",
  placeholderColor = "placeholder:text-primary-6",
  iconColor = "#515F6F",
  iconPosition = "right",
  customStyles = {},
  customInputStyles = {},
  customIconStyles = {},
  rightOffset = 2,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const handleDebounce = setTimeout(() => {
      if (searchQuery.length >= minLength) {
        onSearch(searchQuery);
      } else {
        onSearch("");
      }
    }, debounceTime);

    return () => {
      clearTimeout(handleDebounce);
    };
  }, [searchQuery, minLength, debounceTime, onSearch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div
      className={`relative flex items-center ${width} ${height} `}
      style={customStyles}
    >
      {iconPosition === "left" && (
        <div
          className="absolute left-3 flex items-center justify-center"
          style={customIconStyles}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5305 12.9693L10.5624 9.99991C11.4524 8.84021 11.8678 7.38541 11.7246 5.93063C11.5814 4.47585 10.8901 3.13002 9.79107 2.16616C8.69203 1.2023 7.26751 0.692572 5.80648 0.740389C4.34544 0.788205 2.9573 1.38998 1.92364 2.42364C0.88998 3.4573 0.288205 4.84544 0.240389 6.30648C0.192572 7.76751 0.702295 9.19203 1.66616 10.2911C2.63002 11.3901 3.97585 12.0814 5.43063 12.2246C6.88541 12.3678 8.34021 11.9524 9.49991 11.0624L12.4705 14.0337C12.5403 14.1034 12.6231 14.1588 12.7143 14.1965C12.8054 14.2343 12.9031 14.2537 13.0018 14.2537C13.1005 14.2537 13.1981 14.2343 13.2893 14.1965C13.3805 14.1588 13.4633 14.1034 13.533 14.0337C13.6028 13.9639 13.6581 13.8811 13.6959 13.7899C13.7337 13.6988 13.7531 13.6011 13.7531 13.5024C13.7531 13.4038 13.7337 13.3061 13.6959 13.2149C13.6581 13.1238 13.6028 13.0409 13.533 12.9712L13.5305 12.9693ZM1.74991 6.49991C1.74991 5.65934 1.99917 4.83765 2.46617 4.13874C2.93316 3.43983 3.59692 2.8951 4.37351 2.57343C5.1501 2.25175 6.00463 2.16759 6.82905 2.33158C7.65347 2.49556 8.41075 2.90034 9.00512 3.49471C9.59949 4.08908 10.0043 4.84636 10.1683 5.67078C10.3322 6.4952 10.2481 7.34973 9.9264 8.12632C9.60473 8.90291 9.06 9.56666 8.36109 10.0337C7.66218 10.5007 6.84049 10.7499 5.99991 10.7499C4.8731 10.7488 3.79277 10.3006 2.99599 9.50384C2.19921 8.70706 1.75107 7.62673 1.74991 6.49991Z"
              fill={iconColor}
            />
          </svg>
        </div>
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        className={`pl-3 pr-3 border-2 ${width} ${borderColor} ${textColor} rounded-lg shadow-sm focus:outline-none ${placeholderColor} ${height} ${customInputStyles}`}
      />
      {iconPosition === "right" && (
        <div
          className={`absolute right-${rightOffset} flex items-center justify-center`}
          style={customIconStyles}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5305 12.9693L10.5624 9.99991C11.4524 8.84021 11.8678 7.38541 11.7246 5.93063C11.5814 4.47585 10.8901 3.13002 9.79107 2.16616C8.69203 1.2023 7.26751 0.692572 5.80648 0.740389C4.34544 0.788205 2.9573 1.38998 1.92364 2.42364C0.88998 3.4573 0.288205 4.84544 0.240389 6.30648C0.192572 7.76751 0.702295 9.19203 1.66616 10.2911C2.63002 11.3901 3.97585 12.0814 5.43063 12.2246C6.88541 12.3678 8.34021 11.9524 9.49991 11.0624L12.4705 14.0337C12.5403 14.1034 12.6231 14.1588 12.7143 14.1965C12.8054 14.2343 12.9031 14.2537 13.0018 14.2537C13.1005 14.2537 13.1981 14.2343 13.2893 14.1965C13.3805 14.1588 13.4633 14.1034 13.533 14.0337C13.6028 13.9639 13.6581 13.8811 13.6959 13.7899C13.7337 13.6988 13.7531 13.6011 13.7531 13.5024C13.7531 13.4038 13.7337 13.3061 13.6959 13.2149C13.6581 13.1238 13.6028 13.0409 13.533 12.9712L13.5305 12.9693ZM1.74991 6.49991C1.74991 5.65934 1.99917 4.83765 2.46617 4.13874C2.93316 3.43983 3.59692 2.8951 4.37351 2.57343C5.1501 2.25175 6.00463 2.16759 6.82905 2.33158C7.65347 2.49556 8.41075 2.90034 9.00512 3.49471C9.59949 4.08908 10.0043 4.84636 10.1683 5.67078C10.3322 6.4952 10.2481 7.34973 9.9264 8.12632C9.60473 8.90291 9.06 9.56666 8.36109 10.0337C7.66218 10.5007 6.84049 10.7499 5.99991 10.7499C4.8731 10.7488 3.79277 10.3006 2.99599 9.50384C2.19921 8.70706 1.75107 7.62673 1.74991 6.49991Z"
              fill={iconColor}
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
