import React, { useEffect, useState } from "react";
import MultiCardCarousel from "../global-components/MultiCardCarousel";
import Pagination from "../global-components/Pagination";

import CustomTable from "../global-components/CustomTable";
import { LocationIcon, Motorcycle } from "../icons/index";
import TotalSales from "../../components/weekly-component/TotalSales";
import AverageOperationalScore from "../../components/weekly-component/AverageOperationalScore";
import AverageRating from "../../components/weekly-component/AverageRating";
import { useCallPostMutation, useCallGetQuery } from "../../services/api";
import { useSelector } from "react-redux";

import CustomDropdownCheckbox from "../../components/global-components/CustomDropdownChekbox";
import ParentDatePicker from "../../components/global-components/CustomDatePicker/ParantDatePicker";
import ChartComponent from "../../components/global-components/Charts";
import { useDispatch } from "react-redux";
import DrodownSkelton from "../skelaton/DrodownSkelton";
import { format } from "date-fns";
import {
  handleDateChange,
  setSelectedLocation,
} from "../../redux/slices/filter-slice";

import DatePickerSkeleton from "../../../src/components/skelaton/DatePickerSkelton";
import { useSearchParams } from "react-router-dom";

export default function OverviewContent() {
  const dispatch = useDispatch();
  const appliedFilter = useSelector((state) => state?.filter?.addFilter);
  const [reviewData, setReviewData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [weeklyOperationsData, setWeeklyOperationsData] = useState({});
  const [selectReview, setSelectReview] = useState(["all"]);
  const [selectRefund, setSelectRefund] = useState(["all"]);
  const [selectLocation, setSelectLocation] = useState(["all"]);
  const [reviewOptions, setReviewOptions] = useState([]);
  const [refundOptions, setRefundOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refundLoading, setRefundLoading] = useState(true);
  const [weeklyLoading, setWeeklyLoading] = useState(true);
  const weeklyDates = useSelector((state) => state.filter?.weeklyDates);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [searchParams] = useSearchParams();
  const redirectWeekly = searchParams?.get("redirect");

  const GetDropDownData = useCallGetQuery("weekly-operations/get-location");
  const ReduxSelectedLocations = useSelector(
    (state) => state.filter.selectedLocations
  );

  const handleFetchLocation = async () => {
    const { data } = GetDropDownData;
    if (data?.status) {
      const location = data?.data?.map((item) => ({
        value: item.Name,
        label: item.Name,
      }));

      // Sort the location array alphabetically by label
      location.sort((a, b) => a.label.localeCompare(b.label));

      setLocationData(location);

      // Dispatch the sorted data if needed
      // dispatch(setweeklyOperationsData(location));
    }
  };

  const [postData, { isLoading: isPosting }] = useCallPostMutation();

  const handleFetchReviewData = async () => {
    try {
      setLoading(true);
      const result = await postData({
        url: "weekly-operations/get-weekly-oprations-review",
        body: {
          startDate: weeklyDates.start,
          endDate: weeklyDates.end,
          locations: ReduxSelectedLocations, //selectLocation.length === locationData.length ? ["all"] : (appliedFilter?.weeklyOperationsData?.length > 0 ? appliedFilter?.weeklyOperationsData : selectLocation),
          sort:
            reviewOptions?.length === selectReview?.length
              ? ["all"]
              : selectReview,
          page: currentBatch,
        },
      });
      if (result?.data?.status) {
        if (currentBatch > 1) {
          setReviewData((prevData) => [...prevData, ...result?.data?.data]);
        } else {
          setReviewData(result?.data?.data);
        }
        setTotalRecords(result?.data?.totalRecords);
        const newReviewOptions = [];
        if (result?.data?.check?.deliveroo) {
          newReviewOptions.push({ value: "deliveroo", label: "Deliveroo" });
        }
        if (result?.data?.check?.justeat) {
          newReviewOptions.push({ value: "justeat", label: "JustEat" });
        }
        if (result?.data?.check?.ubereats) {
          newReviewOptions.push({ value: "ubereats", label: "Uber Eats" });
        }
        setReviewOptions(newReviewOptions);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  //   setSelectedLocation
  const handleFetchRefundData = async () => {
    try {
      setRefundLoading(true);
      const result = await postData({
        url: "weekly-operations/get-weekly-oprations-refund",
        body: {
          startDate: weeklyDates.start,
          endDate: weeklyDates.end,
          deliveryApp:
            refundOptions?.length === selectRefund?.length
              ? ["all"]
              : selectRefund,
          locations: ReduxSelectedLocations, //selectLocation.length === locationData.length ? ["all"] : (appliedFilter?.weeklyOperationsData?.length > 0 ? appliedFilter?.weeklyOperationsData : selectLocation),
          itemsPerPage: itemsPerPage,
          page: currentPage,
        },
      });
      if (result?.data?.status) {
        setRefundData(result?.data?.refund);
        setTotalPage(result?.data?.totalPage);

        const newRefundOptions = [];
        if (result?.data?.data?.Deliveroo) {
          newRefundOptions.push({ value: "deliveroo", label: "Deliveroo" });
        }
        if (result?.data?.data?.Justeat) {
          newRefundOptions.push({ value: "justeat", label: "JustEat" });
        }
        if (result?.data?.data?.Ubereats) {
          newRefundOptions.push({ value: "ubereats", label: "Uber Eats" });
        }
        setRefundOptions(newRefundOptions);
      }
      setRefundLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleFetchWeeklyOperationsData = async () => {
    try {
      setWeeklyLoading(true);

      const result = await postData({
        url: "weekly-operations/get-weekly-oprations-data",
        body: {
          startDate: weeklyDates.start,
          endDate: weeklyDates.end,
          locations: ReduxSelectedLocations, //appliedFilter?.weeklyOperationsData?.length > 0 ? appliedFilter?.weeklyOperationsData : selectLocation,
          //selectLocation.length === locationData.length ? ["all"] : (appliedFilter?.weeklyOperationsData?.length > 0 ? appliedFilter?.weeklyOperationsData : selectLocation),
        },
      });
      if (result?.data?.status) {
        setWeeklyOperationsData(result?.data?.data);
        console.log("Weeklyoperations", result?.data?.data);
      }
      setWeeklyLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSelectionChange = (selectedValues, dropdownType) => {
    if (dropdownType === "location") {
      setSelectLocation(selectedValues);
      dispatch(setSelectedLocation(selectedValues));
    } else if (dropdownType === "refund") {
      setSelectRefund(selectedValues);
    } else if (dropdownType === "customer_review") {
      setSelectReview(selectedValues);
    }
  };

  useEffect(() => {
    handleFetchReviewData();
  }, [selectReview, weeklyDates, selectLocation, currentBatch]);

  useEffect(() => {
    handleFetchRefundData();
  }, [selectRefund, weeklyDates, selectLocation, currentPage, itemsPerPage]);

  useEffect(() => {
    handleFetchWeeklyOperationsData();
  }, [weeklyDates, selectLocation]);

  useEffect(() => {
    if (!GetDropDownData.isLoading) {
      handleFetchLocation();
    }
  }, [GetDropDownData.isLoading]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const resetCarousel = () => {
    setCurrentBatch(1);
    setCurrentIndex(0);
    setTotalRecords(null);
  };

  const handleAfterChange = (currentSlide) => {
    if (
      currentSlide + 16 >= reviewData.length &&
      reviewData.length < totalRecords
    ) {
      setCurrentBatch((prevBatch) => prevBatch + 1);
    }
  };
  const appliedFilterCount = useSelector(
    (state) => state?.filter?.appliedFilterCount
  );

  const { data: fetchInitialWeeklyData, isLoading } = useCallGetQuery(
    "weekly-operations/get-all-weekly-oprations-data"
  );

  const fetchInitialWeeklyDataFunction = () => {
    if (fetchInitialWeeklyData) {
      const formattedStartDate = fetchInitialWeeklyData?.data?.week_start || "";
      const formattedEndDate = fetchInitialWeeklyData?.data?.week_end || "";

      if (formattedStartDate && formattedEndDate) {
        const start = format(formattedStartDate, "yyyy-MM-dd") || "";
        const end = format(formattedEndDate, "yyyy-MM-dd") || "";
        dispatch(
          handleDateChange({
            id: "weeklyDates",
            startDate: start,
            endDate: end,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!redirectWeekly) {
      fetchInitialWeeklyDataFunction();
    }
  }, [fetchInitialWeeklyData]);

  return (
    <div>
      <div className="flex justify-between items-center pb-5 flex-wrap">
        {isLoading ? (
          <DatePickerSkeleton />
        ) : (
          <ParentDatePicker id={"weeklyDates"} />
        )}
        <div className="flex items-center space-x-2 ml">
          <LocationIcon />
          <span className="text-primary-6 text-base font-[600]">Location</span>
          {locationData.length > 0 ? (
            <CustomDropdownCheckbox
              options={locationData}
              onSelectionChange={(data) => {
                handleSelectionChange(
                  locationData.length === data.length ? ["all"] : data,
                  "location"
                );
              }}
              defaultSelectAll={ReduxSelectedLocations?.[0] === "all" ? 1 : 0}
              selectAllText={"All"}
              selectAllValue={"All"}
              dropdownType={"location"}
              width={137}
              placeholder={"Select..."}
              customBorderColor={"#A1AEBF"}
              customBorderRadius={2}
              customBorderWidth={2}
              menuHeight={200}
              menuWidth={160}
              left={"-25px"}
              activeValues={
                ReduxSelectedLocations?.[0] === "all"
                  ? locationData
                  : ReduxSelectedLocations
              }

              //   activeValues={appliedFilter?.weeklyOperationsData || []}
              // activeValues={["Winchester"]}
            />
          ) : (
            <div className="m-3">
              <DrodownSkelton />
            </div>
          )}
        </div>
      </div>

      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
          <TotalSales
            data={weeklyOperationsData?.sales}
            weeklyLoading={weeklyLoading}
          />
          <AverageOperationalScore
            data={weeklyOperationsData?.operational}
            weeklyLoading={weeklyLoading}
          />
          <AverageRating
            data={weeklyOperationsData?.rating}
            weeklyLoading={weeklyLoading}
          />
        </div>
      </div>

      {/*Weeks Operations*/}
      <ChartComponent
        data={weeklyOperationsData?.graph}
        weeklyLoading={weeklyLoading}
      />

      {/*Customer reviews*/}
      <div className="bg-white w-full p-3 rounded-lg shadow">
        <div className="w-full pl-[13px] flex justify-between">
          <h2 className="text-xl font-[600] mb-4">Customer reviews</h2>
          <div className="flex justify-end items-center mb-4 pr-[18px]">
            <p className="text-center text-nowrap mr-2">{<Motorcycle />}</p>
            <label
              htmlFor="sort"
              className="text-center text-nowrap text-primary-6 mr-2"
            >
              Delivery app
            </label>

            <CustomDropdownCheckbox
              options={reviewOptions}
              // defaultSelectAll={true}
              defaultSelectAll={reviewOptions.length ? true : false}
              onSelectionChange={handleSelectionChange}
              selectAllText={"All of them"}
              selectAllValue={"All of them"}
              showMenu={reviewOptions?.length > 0 ? true : false}
              dropdownType={"customer_review"}
              placeholder={"Select..."}
              width={137}
              customBorderColor={"#A1AEBF"}
              customBorderRadius={2}
              customBorderWidth={2}
              activeValues={
                selectRefund?.[0] === "all" ? reviewOptions : selectRefund
              }
            />
          </div>
        </div>
        <div className="p-[15px]">
          <MultiCardCarousel
            reviewData={reviewData}
            loading={loading}
            handleAfterChange={handleAfterChange}
          />
        </div>
      </div>

      {/*Refunds*/}
      <div className="bg-white w-full rounded-lg shadow my-5 px-6 py-4">
        <div className="w-full pl-2 flex justify-between items-center flex-wrap">
          <h2 className="text-xl font-semibold">Refunds</h2>
          <div className="flex gap-5 items-center justify-center pb-4 flex-wrap">
            <div className="flex justify-center items-center gap-2">
              <p className="text-center text-nowrap ml-1">{<Motorcycle />}</p>
              <p className="text-center text-nowrap text-primary-6 ">
                Delivery App
              </p>
            </div>
            <div>
              <CustomDropdownCheckbox
                options={refundOptions}
                defaultSelectAll={refundOptions?.length > 0 ? true : false}
                onSelectionChange={handleSelectionChange}
                selectAllText={"All of them"}
                selectAllValue={"All of them"}
                showMenu={refundOptions?.length > 0 ? true : false}
                dropdownType={"refund"}
                placeholder={"Select..."}
                width={137}
                customBorderColor={"#A1AEBF"}
                customBorderRadius={2}
                customBorderWidth={2}
                activeValues={
                  selectReview?.[0] === "all" ? reviewOptions : selectReview
                }
              />
            </div>
            <Pagination
              totalPages={totalPage}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </div>
        </div>

        <CustomTable tabledata={refundData} refundLoading={refundLoading} />
      </div>
    </div>
  );
}
