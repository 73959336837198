import React from "react";

import PagesRoutes from "./routes/Routes";
import { Route, Routes } from "react-router-dom";
import Signin from "./pages/Login/Signin";
import Signup from "./pages/Signup/Signup";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/reset-password";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Signin />} />

        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
      </Routes>
      <PagesRoutes />
    </>
  );
}

export default App;
