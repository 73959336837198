import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RightIcon, tableYellowEyeIcon, tableEyeIcon } from "../icons";
import { CrossIcon } from "../icons";
import { UberEats, JustEat, Deliveroo } from "../icons";
import RefundDetailsModal from "../weekly-component/RefundDetailsModal";

const CustomTable = ({ tabledata, refundLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        className="overflow-x-auto border rounded-lg custom-scrollbar"
        style={{ maxHeight: "500px", overflowY: "auto" }}
      >
        <table className="min-w-full leading-normal">
          <thead className="bg-primary-2">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium">
                Delivery App
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-center  text-nowrap text-primary-6  text-base font-medium">
                Incorrect items
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-center text-primary-6  text-base font-medium text-nowrap">
                Missing items
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-center text-primary-6  text-base font-medium text-nowrap">
                Incorrect prep
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-center text-primary-6  text-base font-medium text-nowrap">
                Misc
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-center text-primary-6  text-base font-medium text-nowrap">
                Never received
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-primary-2 text-primary-6  text-base font-medium text-nowrap text-left">
                Client feedback
              </th>
              <th className="pr-5 pl-[42px] py-3 border-b-2 border-gray-200 bg-primary-2  text-primary-6  text-base font-medium uppercase text-nowrap">
                {tableEyeIcon()}
              </th>
            </tr>
          </thead>
          <tbody>
            {refundLoading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <Skeleton circle={true} width={40} height={40} />
                      </div>
                      <div className="ml-3">
                        <Skeleton width={100} />
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <Skeleton width={40} />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <Skeleton width={40} />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white font-[14px] leading-[18.5px] text-center">
                    <Skeleton width={40} />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <Skeleton width={40} />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <Skeleton width={40} />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <Skeleton width={100} />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <div className="flex justify-center items-center gap-2">
                      <Skeleton width={60} />
                      <Skeleton width={20} />
                    </div>
                  </td>
                </tr>
              ))
            ) : tabledata?.length > 0 ? (
              tabledata.map((item, index) => (
                <tr key={index}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        {item["Delivery App"] === "Just Eat" && <JustEat />}
                        {item["Delivery App"] === "Uber Eats" && <UberEats />}
                        {item["Delivery App"] === "Deliveroo" && <Deliveroo />}
                      </div>
                      <div className="ml-3">
                        <p className="text-gray-900 whitespace-no-wrap font-semibold">
                          {item["Delivery App"]}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {item["Incorrect items"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {item["Missing items"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white font-[14px] leading-[18.5px] text-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {item["Incorrect prep"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white font-[14px] leading-[18.5px] text-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {item["Misc."]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <p className="text-gray-900 whitespace-no-wrap pl-8">
                      {item["Never received"] ? (
                        <RightIcon />
                      ) : (
                        <span role="img" aria-label="cross-mark">
                          <CrossIcon />
                        </span>
                      )}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p
                      className="text-gray-900 whitespace-no-wrap"
                      style={{ fontWeight: 400 }}
                    >
                      {item["Client feedback"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                    <div
                      className="flex justify-center items-center gap-2 cursor-pointer"
                      onClick={() => openModal(item)}
                    >
                      <p className="text-orange-5 text-center text-nowrap">
                        View
                      </p>
                      <p className="text-center text-nowrap ml-1">
                        {tableYellowEyeIcon()}
                      </p>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full text-center p-8 font-500" colSpan={8}>
                  No refund available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <RefundDetailsModal
          closeModal={closeModal}
          selectedItem={selectedItem}
        />
      )}
    </div>
  );
};

export default CustomTable;
