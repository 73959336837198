import React, { useState } from "react";
import GlobalButton from "./GlobalButton";
import GlobalModal from "./GlobalModal";
import { FilterIcon } from "../../../components/icons";

const Filter = ({ dropdownOptions, onApply, left = 0, top = 0 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="relative">
      <GlobalButton
        onClick={handleOpenModal}
        label="Add filters"
        icon={FilterIcon}
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        dropdownOptions={dropdownOptions}
        onApply={onApply}
        left={left}
        top={top}
      />
      <GlobalModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        dropdownOptions={dropdownOptions}
        onApply={onApply}
        left={left}
        top={top}
      />
    </div>
  );
};

export default Filter;
