import React, { useState } from "react";
import { CrossIconGrey, Delete, FilterIcon } from "../icons";
import CustomButton from "./CustomButton";
import { opscore, ratings, sales, status } from "../data/dropdowndata";
import CustomDropdownCheckbox from "./CustomDropdownChekbox";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterValue,
  checkAppliedFilters,
  clearAddFilters,
} from "../../redux/slices/filter-slice";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

const Modalstyle = {
  position: "absolute",
  top: "50%",
  left: "67%",
  transform: "translate(-50%, -50%)",
  width: 400,

  p: 4,
};

const AddFilters = ({ modalbuttonname = "Add filters", handleApply }) => {
  const dispatch = useDispatch();
  const appliedFilterCount = useSelector(
    (state) => state?.filter?.appliedFilterCount
  );
  const appliedFilter = useSelector((state) => state?.filter?.addFilter);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  const applyFilter = () => setIsOpen(false);

  const handleResetClick = () => {
    dispatch(clearAddFilters());
    setIsOpen(false);
  };

  const handleSelectionChange = (selectedValues, dropdownType) => {
    dispatch(setFilterValue({ dropdownType, selectedValues }));
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="relative">
        {/* <div className="relative "> */}
        <button
          className=" shadow-sm md:w-32 bg-white border-2 border-[#A1AEBF] text-primary-7 rounded-lg font-semibold px-4 py-1 flex items-center justify-center space-x-2 h-10 "
          onClick={handleOpenModal}
          style={{ padding: 0 }}
        >
          <span className="text-base font-semibold text-primary-7 text-nowrap">
            {modalbuttonname}
          </span>
          <FilterIcon className="w-5 h-5" /> {/* Adjust size as needed */}
        </button>
        {appliedFilterCount > 0 && (
          <div className="w-4 h-4 flex items-center justify-center bg-orange-500 text-white rounded-full absolute -top-1 -right-1">
            <span className="text-[0.65rem] font-semibold">
              {appliedFilterCount}
            </span>
          </div>
        )}
        {/* </div> */}

        <Modal
          BackdropProps={{
            sx: { backgroundColor: "rgba(0, 0, 0, 0)" },
          }}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Modalstyle}>
            <div className=" inset-0 z-50 flex items-center justify-center p-4">
              <div className="bg-white rounded-xl shadow-md mx-auto">
                <div className="pt-4 pb-2 px-4 flex justify-between items-center">
                  <p className="font-semibold">Filter by</p>
                  <button
                    onClick={handleCloseModal}
                    className="text-gray-600 focus:outline-none"
                  >
                    <CrossIconGrey />
                  </button>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 ">
                  <div className="px-4 py-2 ">
                    <label
                      htmlFor="opscoreDropdown"
                      className="block text-base font-medium text-primary-6 pb-2"
                    >
                      OP score
                    </label>
                    <CustomDropdownCheckbox
                      id="opscoreDropdown"
                      options={opscore}
                      defaultSelectAll={
                        appliedFilter?.opscore?.length > 0 ? false : true
                      }
                      onSelectionChange={handleSelectionChange}
                      dropdownType={"opscore"}
                      activeValues={appliedFilter?.opscore || []}
                    />
                  </div>
                  <div className="px-4 py-2">
                    <label
                      htmlFor="ratingsDropdown"
                      className="block text-base font-[500] text-primary-6 pb-2"
                    >
                      Rating
                    </label>
                    <CustomDropdownCheckbox
                      id="ratingsDropdown"
                      options={ratings}
                      defaultSelectAll={
                        appliedFilter?.ratings?.length > 0 ? false : true
                      }
                      onSelectionChange={handleSelectionChange}
                      dropdownType={"ratings"}
                      activeValues={appliedFilter?.ratings || []}
                    />
                  </div>
                  <div className="px-4 py-2">
                    <label
                      htmlFor="salesDropdown"
                      className="block text-sm font-medium text-gray-700 pb-2"
                    >
                      Sales
                    </label>
                    <CustomDropdownCheckbox
                      id="salesDropdown"
                      options={sales}
                      defaultSelectAll={
                        appliedFilter?.sales?.length > 0 ? false : true
                      }
                      onSelectionChange={handleSelectionChange}
                      dropdownType={"sales"}
                      activeValues={appliedFilter?.sales || []}
                    />
                  </div>
                  <div className="px-4 py-2">
                    <label
                      htmlFor="statusDropdown"
                      className="block text-sm font-medium text-gray-700 pb-2"
                    >
                      Status
                    </label>
                    <CustomDropdownCheckbox
                      id="statusDropdown"
                      options={status}
                      defaultSelectAll={
                        appliedFilter?.status?.length > 0 ? false : true
                      }
                      onSelectionChange={handleSelectionChange}
                      dropdownType={"status"}
                      activeValues={appliedFilter?.status || []}
                    />
                  </div>
                </div>

                <div className="px-4 pb-4 flex flex-col sm:flex-row items-center justify-between gap-4 mt-4">
                  <CustomButton
                    name="Reset"
                    onClick={handleResetClick}
                    className="w-full sm:w-40 border border-gray-300 rounded-lg h-8 text-black flex items-center justify-center "
                    style={{
                      background: "white",
                      color: "black",
                      border: "1px solid #EEF2F8",
                      borderRadius: "10px",
                    }}
                    icon={Delete}
                  />
                  <CustomButton
                    onClick={() => {
                      applyFilter();
                      dispatch(checkAppliedFilters());
                      handleApply();
                    }}
                    name="Apply"
                    style={{
                      borderRadius: "10px",
                    }}
                    className="w-full sm:w-40 bg-primary-7 border border-gray-300 rounded-lg h-8 text-white flex items-center justify-center gap-2"
                  />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AddFilters;
