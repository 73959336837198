import React, { useState } from "react";
import OverviewContent from "../../components/weekly-component/OverviewContent";
import Button from "../../components/global-components/Button";
import ControlOverview from "../../components/weekly-component/ControlOverview";

export default function WeeklyOperations() {
  const [activeTab, setActiveTab] = useState("Overview");

  return (
    <div>
      <div className="flex flex-row items-center gap-x-2 w-full mb-8">
        <div className="flex space-x-8 leading-[1.8] ">
          {["Overview", "Control Overview"].map((tab) => (
            <button
              key={tab}
              className={`${
                activeTab === tab
                  ? "border-b-[3.4px] border-black text-xl font-extrabold tracking-wider"
                  : "text-[#A1AEBF] text-xl Product_Sans_Medium font-medium "
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* <div className="flex items-end ml-auto">
          <Button />
        </div> */}
      </div>

      {activeTab === "Overview" && <OverviewContent />}
      {activeTab === "Control Overview" && <ControlOverview />}
    </div>
  );
}
