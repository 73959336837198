import React from "react";
import { PiCalculatorBold } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { MarketingSvg } from "../icons";
import { useLocation } from "react-router-dom";

const SidebarItems = () => {
  const location = useLocation();

  const marketingRoute = "/marketing";
  const colorCode = "#071B32";

  const items = [
    {
      icon: <IoLocationOutline />,
      name: "Locations",
      link: "/locations",
    },
    {
      icon: <PiCalculatorBold />,
      name: "Daily operations",
      link: "/daily-operations",
    },
    {
      icon: <PiCalculatorBold />,
      name: "Sales and Operations",
      link: "/weekly-operations",
    },
    {
      icon:
        location.pathname === marketingRoute ? (
          <MarketingSvg color={colorCode} />
        ) : (
          <MarketingSvg />
        ),
      name: "Marketing",
      link: marketingRoute,
    },
  ];

  return items;
};

export default SidebarItems;
