import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
} from "chart.js";

// Registering components used in Chart.js
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement
);

const LineChart = () => {
  const formatDate = (dateString) => {
    const [start, end] = dateString.split(" - ");
    const [startDay, startMonth] = start.split(".");
    const [endDay, endMonth] = end.split(".");
    const year = new Date().getFullYear();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${parseInt(startDay)} ${
      months[parseInt(startMonth) - 1]
    }, ${year} - ${parseInt(endDay)} ${
      months[parseInt(endMonth) - 1]
    }, ${year}`;
  };

  const data = {
    labels: [
      "23.04 - 30.04",
      "01.05 - 08.05",
      "09.05 - 16.05",
      "17.05 - 24.05",
      "25.05 - 01.06",
      "02.06 - 09.06",
      "10.06 - 17.06",
      "18.06 - 25.06",
    ],
    datasets: [
      {
        label: "Net Revenue",
        data: [10000, 11000, 11678.9, 12000, 11000, 10500, 12000, 12500],
        borderColor: "#059CD5",
        backgroundColor: "transparent",
        borderWidth: 2,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointRadius: 50,
        tension: 0,
      },
      {
        label: "Gross Revenue",
        data: [11000, 9500, 11000, 10000, 10500, 11500, 10000, 10500],
        borderColor: "#A500FF",
        backgroundColor: "transparent",
        borderWidth: 2,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointRadius: 50,
        tension: 0,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        grid: {
          display: true, // Show the y-axis grid lines
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => `£${value / 1000}k`, // Customize the y-axis labels
        },
        border: {
          display: false,
        },
      },

      x: {
        grid: {
          display: false, // Hide the x-axis grid lines
        },
        border: {
          display: false, // Hide the x-axis line
        },
        // ticks: {
        //   autoSkip: false,
        // },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: "#ffffff", // White background
        titleColor: "#000000", // Color of the title text
        bodyColor: "#000000", // Color of the body text
        borderColor: "#EEF2F8", // Border color
        borderWidth: 1, // Border width
        padding: 10,
        callbacks: {
          title: function (context) {
            return formatDate(context[0].label);
          },
          label: function (context) {
            const value = context.raw;
            return [`£${value.toFixed(2)}`];
          },
        },
        titleFont: {
          weight: "normal",
        },
        bodyFont: {
          weight: "bold",
        },
        bodyAlign: "left", // Changed from 'center' to 'left'
        titleAlign: "center",
      },
      legend: {
        display: false, // Hide the legend
      },
    },
    responsive: true, // Ensure the chart is responsive
    maintainAspectRatio: false, // Allow chart to adjust height
  };

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
