import React from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarIcon, SidebarIconLeft } from "../icons";
import SidebarItems from "../data/sidebarItem";
import { useDispatch } from "react-redux";
import { clearAllFilters } from "../../redux/slices/filter-slice";

const Sidebar = ({ isOpen, toggleSidebar, sidebarWidth }) => {
  const items = SidebarItems();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <div
      className={` fixed top-0 left-0 h-full bg-white space-y-6 py-7 px-3 transform  border-r-2 border-[#eef2f8] ${
        isOpen ? "translate-x-0" : "flex flex-col items-center"
      }`}
      style={{ width: sidebarWidth }}
    >
      <div
        className={`flex items-center gap-4 relative my-divider ${
          isOpen ? "" : "flex-col"
        }`}
      >
        <button onClick={toggleSidebar} className="relative">
          <div className={`${isOpen ? "" : ""}`}>
            {isOpen ? <SidebarIcon /> : <SidebarIconLeft />}
          </div>
        </button>
        {isOpen && (
          // <p className="text-black font-extrabold text-center text-lg">
          //   GROWTHKITCHEN
          // </p>
          <svg
            width="152"
            height="12"
            viewBox="0 0 152 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: "em" }}
          >
            <g clipPath="url(#clip0_199_4398)">
              <mask
                id="mask0_199_4398"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="152"
                height="12"
              >
                <path d="M152 0H0V12H152V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_199_4398)">
                <path
                  d="M18.2299 2.88189H16.7368V5.17323H18.3887C19.433 5.17323 19.9277 4.75394 19.9277 4.05118C19.9277 3.25689 19.4453 2.88189 18.2269 2.88189M16.7337 11.8435H13.5244V0.156494H18.3399C21.5644 0.156494 23.2836 1.02756 23.2836 3.25689C23.2836 4.75394 22.4316 5.54823 21.2285 5.93799C22.4469 6.25098 22.9141 7.0748 22.9141 8.33858V9.7559C22.9141 10.5502 23.1859 11.1112 23.6347 11.4715V11.8465H20.3613C19.9613 11.5984 19.7048 11.1289 19.7048 10.1161V8.69882C19.7048 8.12303 19.433 7.67126 18.7429 7.67126H16.7368V11.8494L16.7337 11.8435Z"
                  fill="#010B1B"
                />
                <path
                  d="M27.3085 6C27.3085 7.91634 28.4322 9.03839 30.0537 9.03839C31.6751 9.03839 32.7805 7.91634 32.7805 6C32.7805 4.08366 31.6568 2.96161 30.0537 2.96161C28.4505 2.96161 27.3085 4.11614 27.3085 6ZM36.1516 6C36.1516 9.61417 33.5836 12 30.0537 12C26.5237 12 23.9404 9.61417 23.9404 6C23.9404 2.38583 26.5207 0 30.0537 0C33.5866 0 36.1516 2.40059 36.1516 6Z"
                  fill="#010B1B"
                />
              </g>
              <path
                d="M49.1444 0.156494L47.5413 6.23327L45.6451 0.156494H42.8541L40.9762 6.15649L39.3547 0.156494H35.8584L39.2753 11.8435H42.1945L44.2496 5.12598L46.3046 11.8435H49.065L52.6438 0.156494H49.1444Z"
                fill="#010B1B"
              />
              <path
                d="M52.9062 0.156494V2.80512H56.5339V11.8435H59.7432V2.80512H63.3708V0.156494H52.9062Z"
                fill="#010B1B"
              />
              <path
                d="M71.7041 0.156494V4.44094H67.2093V0.156494H64V11.8435H67.2093V7.24606H71.7041V11.8435H74.9134V0.156494H71.7041Z"
                fill="#010B1B"
              />
              <path
                d="M85.402 0.156494L81.3743 4.45866V0.156494H78.165V11.8435H81.3743V8.41535L82.4675 7.30807L85.8997 11.8435H89.7991L84.6019 5.11122L89.4785 0.156494H85.402Z"
                fill="#010B1B"
              />
              <path
                d="M93.6067 0.156494H90.3975V11.8465H93.6067V0.156494Z"
                fill="#010B1B"
              />
              <path
                d="M94.2109 0.156494V2.80512H97.8386V11.8435H101.048V2.80512H104.675V0.156494H94.2109Z"
                fill="#010B1B"
              />
              <mask
                id="mask1_199_4398"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="152"
                height="12"
              >
                <path d="M152 0H0V12H152V0Z" fill="white" />
              </mask>
              <g mask="url(#mask1_199_4398)">
                <path
                  d="M113.672 6.87402L116.704 7.91929C116.047 10.4291 113.977 12.003 110.847 12.003C107.253 12.003 104.749 9.61713 104.749 6.00295C104.749 2.38878 107.25 0 110.847 0C112.435 0 113.736 0.375 114.731 1.12205C115.742 1.86909 116.398 2.85236 116.737 4.05118L113.607 5.17323C113.272 3.8002 112.276 2.95866 110.847 2.95866C109.226 2.95866 108.102 4.11319 108.102 5.99705C108.102 7.88091 109.226 9.03543 110.847 9.03543C112.325 9.03543 113.302 8.1939 113.672 6.86811"
                  fill="#010B1B"
                />
              </g>
              <path
                d="M125.33 0.156494V4.44094H120.835V0.156494H117.626V11.8435H120.835V7.24606H125.33V11.8435H128.539V0.156494H125.33Z"
                fill="#010B1B"
              />
              <path
                d="M130.109 0.156494V11.8435H139.27V9.03838H133.319V7.10728H138.146V4.6122H133.319V2.88484H139.27V0.156494H130.109Z"
                fill="#010B1B"
              />
              <path
                d="M148.949 0.156494V7.52657L144.842 0.156494H140.717V11.8435H143.767V4.50295L147.938 11.8435H152V0.156494H148.949Z"
                fill="#010B1B"
              />
              <mask
                id="mask2_199_4398"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="152"
                height="12"
              >
                <path d="M152 0H0V12H152V0Z" fill="white" />
              </mask>
              <g mask="url(#mask2_199_4398)">
                <path
                  d="M12.1471 7.0128V6H6.46133V8.21752H6.59568L8.5011 7.96654C8.3698 8.20571 8.18964 8.40354 7.96062 8.56004C7.47816 8.8878 6.91631 9.04429 6.25979 9.04429C4.52537 9.04429 3.37113 7.92224 3.37113 6.00591C3.37113 4.08957 4.49484 2.96752 6.11628 2.96752C6.77584 2.96752 7.35297 3.15354 7.8507 3.51378C8.22934 3.78839 8.45835 4.125 8.61103 4.55906H12.1012C11.6585 1.72146 9.1393 0 6.11322 0C2.58331 0 0 2.40059 0 6C0 9.59941 2.45506 12 5.7926 12C7.71939 12 8.90723 11.3297 9.69199 10.0837C9.57901 10.5974 9.4019 11.1732 9.20953 11.69V11.8465H12.1471V7.01575V7.0128Z"
                  fill="#010B1B"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_199_4398">
                <rect width="152" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>

      <div className={`${isOpen ? "" : "flex flex-col items-center w-full"}`}>
        {items.map((item, index) => {
          const isActive = location.pathname === item.link;
          return (
            <Link
              key={index}
              onClick={() => dispatch(clearAllFilters())}
              to={item.link}
              className={`flex items-center gap-4 py-2.5 px-4 hover:font-semibold hover:text-black hover:border-l-4  hover:border-green-500 ${
                isOpen ? "" : "justify-center w-full"
              } ${
                isActive
                  ? "text-black font-semibold border-l-4 border-green-500 "
                  : "text-gray-400 border-l-4 border-white"
              }`}
            >
              <span
                className={`icon-wrapper ${
                  isOpen ? "" : "border border-gray-300 p-1 rounded-md"
                }`}
              >
                {item.icon}
              </span>
              {isOpen && <span>{item.name}</span>}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
