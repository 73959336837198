import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Table = () => {
  const loading = false; // Set this to true to see the loading state
  const tableData = [
    {
      location: "New York",
      grossSales: "$10,000 (5.2%)",
      netSales: "$8,500 (4.5%)",
      budgetSpent: "18%",
      salesRevenueFromPromotions: "$2,500",
    },
    {
      location: "Los Angeles",
      grossSales: "$8,500 (4.8%)",
      netSales: "$7,200 (4.1%)",
      budgetSpent: "15%",
      salesRevenueFromPromotions: "$2,000",
    },
    {
      location: "Chicago",
      grossSales: "$7,000 (3.9%)",
      netSales: "$5,900 (3.3%)",
      budgetSpent: "22%",
      salesRevenueFromPromotions: "$1,800",
    },
    {
      location: "Houston",
      grossSales: "$6,500 (3.6%)",
      netSales: "$5,500 (3.1%)",
      budgetSpent: "12%",
      salesRevenueFromPromotions: "$1,500",
    },
    {
      location: "Phoenix",
      grossSales: "$5,500 (3.1%)",
      netSales: "$4,700 (2.6%)",
      budgetSpent: "20%",
      salesRevenueFromPromotions: "$1,200",
    },
  ];

  const renderTableContent = () => {
    if (loading) {
      return Array.from({ length: 5 }).map((_, index) => (
        <tr key={index} className="bg-white border-b hover:bg-gray-50">
          <td className="px-4 py-4">
            <Skeleton />
          </td>
          <td className="px-4 py-4">
            <Skeleton />
          </td>
          <td className="px-4 py-4">
            <Skeleton />
          </td>
          <td className="px-4 py-4">
            <Skeleton />
          </td>
          <td className="px-4 py-4">
            <Skeleton />
          </td>
        </tr>
      ));
    }

    if (tableData.length === 0) {
      return (
        <tr className="bg-gray-50">
          <td colSpan="5" className="px-6 py-12 text-center">
            <div className="flex flex-col items-center justify-center">
              <svg
                className="w-12 h-12 text-gray-400 mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <p className="text-gray-900 font-semibold text-lg sm:text-xl">
                No records found
              </p>
              <p className="text-gray-500 mt-2 text-sm sm:text-base">
                Try adjusting your search or filter to find what you're looking
                for.
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return tableData.map((row, index) => (
      <tr key={index} className="bg-white border-t border-b hover:bg-gray-50 ">
        <td className="px-4 py-4 whitespace-nowrap text-primary-7 text-base font-normal text-left">
          {row.location}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-primary-7 text-base font-normal text-center">
          {row.grossSales}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-primary-7 text-base font-normal text-center">
          {row.netSales}
        </td>
        <td
          className={`px-4 py-4 whitespace-nowrap text-primary-7 text-base font-normal text-center`}
        >
          <span
            className={` ${
              parseFloat(row.budgetSpent) <= 15
                ? "rounded-oval"
                : parseFloat(row.budgetSpent) <= 20
                ? "rounded-oval-blue"
                : "rounded-oval-red"
            }`}
          >
            {row.budgetSpent}
          </span>
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-primary-7 text-base font-normal text-center">
          {row.salesRevenueFromPromotions}
        </td>
      </tr>
    ));
  };

  return (
    <div className="w-full overflow-x-auto border border-gray-200 rounded-lg mt-6">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="sticky top-0 z-10 text-primary-6 bg-primary-2 ">
          <tr className="border-b border-primary-4 ">
            <th
              scope="col"
              className="px-4 py-4 text-primary-6 text-base font-medium text-left "
            >
              Location
            </th>
            <th
              scope="col"
              className="px-4 py-4 text-primary-6 text-base font-medium text-center"
            >
              Gross sales
            </th>
            <th
              scope="col"
              className="px-4 py-4 text-primary-6 text-base font-medium text-center"
            >
              Net sales
            </th>
            <th
              scope="col"
              className="px-4 py-4 text-primary-6 text-base font-medium text-center"
            >
              Budget spent
            </th>
            <th
              scope="col"
              className="px-4 py-4 text-primary-6 text-base font-medium text-right"
            >
              Sales revenue from promotions
            </th>
          </tr>
        </thead>
        <tbody>{renderTableContent()}</tbody>
      </table>
    </div>
  );
};

export default Table;
