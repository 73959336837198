import React from "react";
import GlobalModal from "./GlobalModal";

const GlobalButton = ({
  onClick,
  label,
  icon,
  isOpen,
  handleClose,
  dropdownOptions,
  onApply,
  left,
  top,
}) => {
  return (
    <div className="relative">
      <button
        className="shadow-sm md:w-32 bg-white border-2 border-[#A1AEBF] text-primary-7 rounded-lg font-semibold px-4 py-1 flex items-center justify-center space-x-2 h-10"
        onClick={onClick}
        style={{ padding: 0 }}
      >
        <span className="text-base font-semibold text-primary-7 text-nowrap">
          {label}
        </span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.643 1.49437C13.545 1.2729 13.3849 1.08465 13.1819 0.952508C12.979 0.820362 12.742 0.750009 12.4999 0.75H1.49988C1.25775 0.750024 1.02084 0.820368 0.817935 0.952485C0.615029 1.0846 0.454861 1.27281 0.356892 1.49423C0.258923 1.71565 0.227371 1.96076 0.266069 2.19978C0.304766 2.43879 0.412048 2.66143 0.574879 2.84062L0.582379 2.84937L4.74988 7.29688V12C4.74985 12.2263 4.81125 12.4484 4.92754 12.6425C5.04382 12.8367 5.21064 12.9956 5.41017 13.1023C5.60971 13.2091 5.83449 13.2597 6.06053 13.2487C6.28656 13.2377 6.50538 13.1656 6.69363 13.04L8.69363 11.7069C8.86486 11.5926 9.00523 11.4379 9.10227 11.2563C9.19931 11.0748 9.25001 10.8721 9.24988 10.6663V7.29688L13.4168 2.84937L13.4243 2.84062C13.5872 2.66152 13.6946 2.43895 13.7334 2.19996C13.7723 1.96097 13.7408 1.71585 13.643 1.49437ZM7.9555 6.4875C7.82428 6.62597 7.75075 6.80923 7.74988 7V10.5325L6.24988 11.5325V7C6.24969 6.80957 6.17707 6.62635 6.04675 6.4875L2.07675 2.25H11.923L7.9555 6.4875Z"
            fill="#071B32"
          />
        </svg>
      </button>
      {/* <GlobalModal
        isOpen={isOpen}
        handleClose={handleClose}
        dropdownOptions={dropdownOptions}
        onApply={onApply}
        left={left}
        top={top}
      /> */}
    </div>
  );
};

export default GlobalButton;
