import React from "react";

const ActivityHeatmap = () => {
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const hours = Array.from({ length: 17 }, (_, i) => i + 7); // 7:00 to 23:00

  // Generate random data for the heatmap
  const generateData = () => {
    return days.map(
      () => hours.map(() => Math.floor(Math.random() * 9)) // 0 to 4 for 5 levels of intensity
    );
  };

  const data = generateData();

  const getColor = (value) => {
    const colors = [
      "bg-blue-100",
      "bg-blue-200",
      "bg-blue-300",
      "bg-blue-400",
      "bg-blue-500",
      "bg-blue-600",
      "bg-blue-700",
      "bg-blue-800",
      "bg-blue-900",
    ];
    return colors[value] || "bg-gray-100";
  };

  return (
    <div className="w-[100%]">
      <p className="text-xl font-semibold">Activity Heatmap</p>
      <div className="mt-4">
        {days.map((day, dayIndex) => (
          <div key={day} className="flex items-center">
            <div className="w-12 pr-4 text-center text-sm text-primary-6">
              {day}
            </div>

            {hours.map((hour, hourIndex) => (
              <div
                key={hourIndex}
                className={`w-[4rem] m-2 h-6 border border-white ${getColor(
                  data[dayIndex][hourIndex]
                )}`}
              ></div>
            ))}
          </div>
        ))}
        <div className="flex">
          <div className="w-12"></div>
          {hours.map((hour) => (
            <div
              key={hour}
              className="w-[5rem]  text-center text-sm text-primary-6"
            >
              {`${hour.toString().padStart(2, "0")}:00`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityHeatmap;
