import Filter from "../../../../components/shared/Filter/add-filter";

const OverviewAddFilter = () => {
  const opscore = [
    { value: "1", label: "Score 1" },
    { value: "2", label: "Score 2" },
    { value: "3", label: "Score 3" },
    { value: "4", label: "Score 4" },
  ];

  const ratings = [
    { value: "5", label: "Rating 5" },
    { value: "4", label: "Rating 4" },
    { value: "3", label: "Rating 3" },
    { value: "2", label: "Rating 2" },
    { value: "1", label: "Rating 1" },
  ];

  const sales = [
    { value: "high", label: "High Sales" },
    { value: "medium", label: "Medium Sales" },
    { value: "low", label: "Low Sales" },
  ];

  const status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "pending", label: "Pending" },
  ];

  const dropdownOptions = [
    {
      type: "opscore",
      label: "OP score",
      options: opscore,
      defaultSelectAll: true,
    },
    {
      type: "ratings",
      label: "Rating",
      options: ratings,
      defaultSelectAll: true,
    },
    { type: "sales", label: "Sales", options: sales, defaultSelectAll: true },
    {
      type: "status",
      label: "Status",
      options: status,
      defaultSelectAll: true,
    },
  ];

  const handleApply = (selectedValues) => {};

  return (
    <div>
      <Filter
        dropdownOptions={dropdownOptions}
        onApply={handleApply}
        top={"50%"}
        left={"87%"}
      />
    </div>
  );
};

export default OverviewAddFilter;
