import AllocationBudget from "./AllocationBudget";
import Siteoverview from "./Siteoverview";
import SiteReview from "./SiteReview";
import Table from "./Table";

const Offers = () => {
  return (
    <>
      <AllocationBudget />
      <SiteReview />
      <Table />
      <Siteoverview />
    </>
  );
};

export default Offers;
