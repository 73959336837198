import React from "react";
import CustomButton from "../global-components/CustomButton";
import {
  JustEat,
  UberEats,
  Deliveroo,
  LocationIcon,
  CrossIcon,
  RightIcon,
} from "../icons/index";

export default function RefundDetailsModal({ closeModal, selectedItem }) {
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white px-6 py-4 rounded-lg">
          <div className="flex items-center justify-between w-full">
            <p className="text-black font-semibold text-xl">Refund details</p>
            <button onClick={closeModal}>
              <CrossIcon />
            </button>
          </div>

          <div className="my-4 flex gap-2 items-center ">
            <div className="flex-shrink-0 w-10 h-10">
              {selectedItem["Delivery App"] === "Just Eat" && <JustEat />}
              {selectedItem["Delivery App"] === "Uber Eats" && <UberEats />}
              {selectedItem["Delivery App"] === "Deliveroo" && <Deliveroo />}
            </div>
            <span className="font-semibold">
              {selectedItem["Delivery App"]}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <p className="font-semibold text-[16px] flex items-center gap-1">
              <LocationIcon /> {selectedItem["Name"]}
            </p>
            <p className="">
              Date{" "}
              <span className="text-black font-medium">
                {selectedItem["Date"]}
              </span>
            </p>
          </div>

          <div className="overflow-x-auto border rounded-xl mt-4">
            <table className="leading-normal overflow-hidden">
              <thead className="bg-primary-2">
                <tr className="">
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-primary-2 text-center text-xs font-semibold text-gray-600  tracking-wider rounded-tl-md">
                    Incorrect items
                  </th>
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-primary-2 text-center text-xs font-semibold text-gray-600  tracking-wider rounded-tl-md">
                    Missing items
                  </th>
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-primary-2 text-center text-xs font-semibold text-gray-600  tracking-wider rounded-tl-md">
                    Incorrect prep
                  </th>
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-primary-2 text-center text-xs font-semibold text-gray-600  tracking-wider rounded-tl-md">
                    Misc
                  </th>
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-primary-2 text-center text-xs font-semibold text-gray-600  tracking-wider rounded-tl-md">
                    Never Received
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap font-semibold text-center">
                      {selectedItem["Incorrect items"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap font-semibold text-center">
                      {selectedItem["Missing itmes"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap font-semibold text-center">
                      {selectedItem["Incorrect prep"]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap font-semibold text-center">
                      {selectedItem["Misc."]}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap font-semibold flex justify-center">
                      {selectedItem["Never received"] ? (
                        <RightIcon />
                      ) : (
                        <span role="img" aria-label="cross-mark">
                          <CrossIcon />
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="max-w-[520px] mt-6">
            {selectedItem["Client feedback"]?.length > 0 && (
              <>
                <p className="text-md font-semibold">Client's feedback</p>
                <p className="text-left tracking-tighter">
                  {selectedItem["Client feedback"]}
                </p>
              </>
            )}
          </div>

          <div className="flex  mt-4 items-end justify-end w-full">
            <CustomButton
              name="Back"
              bgColor="#071B32"
              style={{
                width: "80px",
                padding: "7px 20px",
                borderRadius: "10px",
              }}
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
